import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import styles from './start.module.css';
import { useState } from 'react';

const Start = ({date, renderer}) => {
    const [chose, setChose] = useState(0)
    const [widget] = useInstance();

    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

                <div className={styles.start_block}>
                    <div className={styles.start_block_title}>Успевайте забрать один из&nbsp;2-х <br/>тарифов программы <span>с&nbsp;выгодой до&nbsp;55%</span> прямо сейчас.<br/> Таких условий больше не&nbsp;будет!</div>
                    {/* <div className={styles.start_block_subtitle}>Действует только <span>до 23:59</span></div> */}
                    {/* <div className={styles.start_block_subtitle}>Попадите в&nbsp;число <b>100&nbsp;счастливиц</b>, которые успеют воспользоваться<br/> наибольшей <b>выгодой&nbsp;80%</b> забрать все бонусы</div> */}
                    <div className={styles.start_block_subtitle}>ПРЕДЛОЖЕНИЕ СГОРИТ ЧЕРЕЗ:</div>
					{/* <div className={styles.start_remains}>ОСТАЛОСЬ НАБОРОВ ПО СЦЕЦЦЕНЕ: <span>{window.lps_settings.other.count}</span><span className={styles.black}>/100</span></div> */}
                    <div className={styles.promo_countdown}>
                        <div className={styles.promo_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div>
                    <div className={styles.n_dates}>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/clock.svg" alt="" />
                            <p>Доступ&nbsp;&mdash; 3&nbsp;месяца</p>
                        </div>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/kalendar.svg" alt="" />
                            <p>Плановая дата старта&nbsp;&mdash; 20&nbsp;января</p>
                        </div>
                    </div>
                    <div className={styles.chose}>
                        <div className={`${styles.chose_item} + ${chose == 0 && styles.chose_selected}`} onClick={()=>{widget.reset();widget.resetOrder();widget.selectByIndex(0); setChose(0)}}>
                            <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}>Тариф &laquo;Базовый&raquo;</div>
                                <div className={styles.chose_head_prices}>
                                    <p>от&nbsp;831,5&nbsp;₽/мес</p>
                                    <div className={styles.chose_head_price}><span>8&nbsp;500&nbsp;₽</span>4&nbsp;990&nbsp;₽<div className={styles.sale}>-41%</div></div>
                                </div>
                                
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>20&nbsp;тренировок от&nbsp;Кати Усмановой</div>
                                {/* <div className={styles.chose_list_price}>5000&nbsp;₽</div> */}
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Программа чистого питания</div>
                                {/* <div className={styles.chose_list_price}>5000&nbsp;₽</div> */}
                            </div>
                        </div>

                        <div className={`${styles.chose_item} + ${chose == 1 && styles.chose_selected}`} onClick={()=>{widget.reset();widget.resetOrder();widget.selectByIndex(1); setChose(1)}}>
                            <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}>Тариф &laquo;Премиум&raquo;</div>
                                <div className={styles.chose_head_prices}>
                                    <p>от&nbsp;1&nbsp;165&nbsp;₽/мес</p>
                                    <div className={styles.chose_head_price}><span>15&nbsp;500&nbsp;₽</span>6&nbsp;990&nbsp;₽<div className={styles.sale}>-55%</div></div>
                                </div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>7-дневный FMD-марафон похудения</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>7-дневный марафон чистого питания</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Комплекс тренировок на&nbsp;неделю марафонов</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Программа чистого питания</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Готовый план питания с&nbsp;рецептами на&nbsp;2&nbsp;недели</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>20&nbsp;тренировок от&nbsp;Кати Усмановой</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Чат с&nbsp;куратором на&nbsp;35&nbsp;дней</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Живой эфир с&nbsp;нутрициологом с&nbsp;ответами на&nbsp;вопросы</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.start_list}>
                        <div className={styles.plus}>Плюс бонусы для любого тарифа:</div>
                        <div className={styles.start_list_item}>
                            <p>+ вебинар от&nbsp;психолога &laquo;Психосоматика лишнего веса&raquo;</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ 5&nbsp;полезных гайдов для улучшения результата</p>
                            <p></p>
                        </div>
                    </div>

                    <div className="mu3_start">
                        <SalesWidget hook={widget} id={window.lps_settings.forms.metod_3_1_id} buttonText="ОФОРМИТЬ ЗАКАЗ" />
                    </div>
                    <div className={styles.pay}>
							<div className={styles.pay_item}>
								<img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/tinkoff.png" alt=""/>
								<p>Рассрочка без процентов от&nbsp;831,5&nbsp;₽ в&nbsp;месяц</p>
								<span>Ты&nbsp;можешь оформить заказ в&nbsp;рассрочку: на&nbsp;6&nbsp;месяцев без процентов и&nbsp;предварительного взноса. Только для граждан&nbsp;РФ.</span>
							</div>
							<div className={styles.pay_item}>
								<img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/doli.png" alt=""/>
								<p>Оплати Долями, без банка, переплат и&nbsp;рисков</p>
								<span>Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; раздели платёж на&nbsp;4&nbsp;части <b>от&nbsp;1 247,5&nbsp;₽.</b> Всего на&nbsp;6&nbsp;недель. Только для граждан&nbsp;РФ.</span>
							</div>
						</div>
                    {/* <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p> */}
                    {/* <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;699&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 1&nbsp;047,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={styles.pay}>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Тинькофф-рассрочка</div>
                                <div className={styles.pay_item_text}>Платите потом&nbsp;&mdash; первый платёж через 30&nbsp;дней <b>от&nbsp;748&nbsp;₽</b> в&nbsp;месяц. Без переплат, без процентов, до&nbsp;6&nbsp;месяцев. Только для граждан&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>По QR-коду</div>
                                <div className={styles.pay_item_text}>Отсканируйте камерой телефона QR-код и&nbsp;перейдите по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить оплату. Удобно со&nbsp;SberPay.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Долями</div>
                                <div className={styles.pay_item_text}>Разделите платёж на&nbsp;4&nbsp;равные части <b>от&nbsp;1247,5&nbsp;₽</b>. Всего на&nbsp;6&nbsp;недель. Без ожидания одобрения банка, переплат и&nbsp;рисков.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>PayPal</div>
                                <div className={styles.pay_item_text}>Введите только электронный адрес и&nbsp;пароль&nbsp;&mdash; не&nbsp;нужно вводить номер карты. Безопасный способ платы, доступный клиентам банков не&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Картой банка&nbsp;РФ<br/> или зарубежного</div>
                                <div className={styles.pay_item_text}>Можете пользоваться своей картой, где&nbsp;бы вы&nbsp;ни&nbsp;находились. Для оплаты картой иностранного банка, выберите оплату через Stripe.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Наличными в&nbsp;терминалах</div>
                                <div className={styles.pay_item_text}>Оплатите ваш заказ наличными через ближайший терминал. Быстро и&nbsp;удобно, без использования кредитных карт и&nbsp;необходимости раскрывать личные данные.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png" alt="" />
                            </div>
                        </div>
                    </div> */}
                </div>

                
            </div>
        </div>
        </>
    )
}

export default Start;