import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Preims from './components/Preims';
import Students from './components/Students';
import Gift from './components/Gift';
import Start from './components/Start';
import Blocks from './components/Blocks';
import Faq from './components/Faq';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_12_Mu_Presale_2 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    // let nextMidnight = new Date(2024,6,19,0,0,0);
    nextMidnight.setHours(24, 0, 0, 0);
    // let nextMidnight = new Date(Date.UTC(2024,11,26,9,0,0));
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours}<span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='-49% на 3 поток «Метода Усмановой» и главный приз за результаты в конкурсе ДО/ПОСЛЕ – поездку в Дубай'/>
        <DocumentDescription value='Выгода 49% на комплексную программу по работе с телом «Метод Усмановой» 3 поток. Главный приз за результаты в конкурсе ДО/ПОСЛЕ – поездку в Дубай' />
        <div className='mu3_presale2'>
              <Promo scroll={scroll} date={date} renderer={renderer} />
              <Preims scroll={scroll} date={date} renderer={renderer}/>
              <Blocks scroll={scroll}/>
              <Students />
              <Gift />
              <Start date={date} renderer={renderer} />
              <Faq/>
              <Footer isColor={'#DEE5EF'} />
        </div>
      </>
    );
};