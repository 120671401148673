import Countdown from 'react-countdown';
import { SalesWidget } from "@com.marathonium/web2-ui";
import { useInstance } from "@com.marathonium/web2-utils";
import styles from './start.module.css';
import { useState } from 'react';

const Start = ({date, renderer}) => {
    const [widget] = useInstance();

    return (
        <>
        <div className={('scrollhere ') + styles.start}>
            <div className="container">

                <div className={styles.start_block}>
                {/* <div className={styles.start_block_pretitle}>Цены прошлого года сгорают!</div> */}
                    <div className={styles.start_block_title}>Успейте забрать комплект <br/>из&nbsp;5&nbsp;бестселлеров <span>с&nbsp;выгодой&nbsp;81%</span></div>
                    <div className={styles.start_block_subtitle}>5&nbsp;программ тренировок для дома, 5&nbsp;гайдов в&nbsp;подарок</div>
                    <div className={styles.price1}>6&nbsp;190&nbsp;₽ <span>32&nbsp;400&nbsp;₽</span></div>
                    <div className={styles.price2}>(от&nbsp;1&nbsp;031&nbsp;₽/мес.)</div>
                    <div className={styles.countdown_start}>
                        <div className={styles.start_block_subtitle}><span>Попадите в&nbsp;число 100&nbsp;счастливиц,</span> которые успеют<br/> воспользоваться наибольшей выгодой</div>
                        {/* <div className={styles.start_remains}>
                            <div>Осталось комплектов:</div> <span>{window.lps_settings.other.count}</span>/100
                        <span className={styles.black}></span>
                        </div> */}
                        
                    </div>

                    <div className={styles.n_dates}>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/clock.svg" alt="" />
                            <p>Продленный доступ&nbsp;&mdash; 6&nbsp;месяцев</p>
                        </div>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/calendar.svg" alt="" />
                            <p>Старт&nbsp;&mdash; сразу после оплаты</p>
                        </div>
                        <div className={styles.n_date}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/chat.svg" alt="" />
                            <p>Чат с&nbsp;куратором&nbsp;&mdash; до&nbsp;26&nbsp;февраля</p>
                        </div>
                    </div>
                    

                    <div className={styles.chose}>
                        <div className={`${styles.chose_item}`}>
                            <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}>Набор из&nbsp;5&nbsp;программ Кати Усмановой</div>
                                {/* <div className={styles.chose_head_prices}>
                                    <p>от&nbsp;748&nbsp;₽/мес</p>
                                    <div className={styles.chose_head_price}><span>17&nbsp;500&nbsp;₽</span>4&nbsp;490&nbsp;₽
                                        <div className={styles.sale}>-80%</div>
                                    </div>
                                </div> */}
                                
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Стройность&nbsp;1.0&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Упругой попы 2.0&raquo;</div>
                                <div className={styles.chose_list_price}>7900&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Плоского живота&raquo;</div>
                                <div className={styles.chose_list_price}>5000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Марафон &laquo;Антицеллюлитный&raquo;</div>
                                <div className={styles.chose_list_price}>7500&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>Курс &laquo;Жиросжигающий&raquo;</div>
                                <div className={styles.chose_list_price}>7000&nbsp;₽</div>
                            </div>
                        </div>
                        <div className={`${styles.chose_item}`}>
                            <div className={styles.chose_head}>
                                <div className={styles.chose_head_name}>+ 5&nbsp;мегаполезных гайдов</div>
                                <div className={styles.chose_head_name}>+ чат с&nbsp;куратором до&nbsp;26&nbsp;февраля включительно</div>
                                {/* <div className={styles.chose_head_prices}>
                                    <p>от&nbsp;748&nbsp;₽/мес</p>
                                    <div className={styles.chose_head_price}>
                                        <span>16&nbsp;687&nbsp;₽</span>
                                        Бесплатно
                                        <div className={styles.sale}>-80%</div>
                                    </div>
                                </div> */}
                                
                            </div>
                            {/* <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>+ участие в&nbsp;розыгрыше 100&nbsp;000&nbsp;₽</div>
                            </div>
                            <div className={styles.chose_list}>
                                <div className={styles.chose_list_name}>+ 8&nbsp;полезных гайдов</div>
                            </div> */}
                        </div>
                    </div>

                    {/* <div className={styles.inf}>
                        <div className={styles.inf_item}>
                            <p>Старт тренировок</p>
                            <p>20 мая</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/calendar.png" alt="" />
                        </div>
                        <div className={styles.inf_item}>
                            <p>Доступ к&nbsp;программам:</p>
                            <p>6 месяцев</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/clock.png" alt="" />
                        </div>
                    </div> */}

                    {/* <div className={styles.total}>
                        <span>6&nbsp;790&nbsp;₽</span>
                        <em>7&nbsp;990&nbsp;₽</em>
                        <p>Курс для зала от&nbsp;Кати Усмановой</p>
                    </div> */}
                    
                    {/* <div className={styles.start_list}> */}
                        {/* <div className={styles.plus}>+ 10&nbsp;гайдов в&nbsp;подарок <b>при оплате в&nbsp;течение 15-ти минут</b></div> */}
                        {/* <div className={styles.start_list_item}>
                            <p>+ участие в&nbsp;розыгрыше поездки в&nbsp;Дубай на&nbsp;двоих</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ возможность выиграть Стайлер Dyson и&nbsp;другие ценные призы</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ подписка на&nbsp;Gymteam на&nbsp;1&nbsp;месяц</p>
                            <p></p>
                        </div>
                        <div className={styles.start_list_item}>
                            <p>+ 5&nbsp;гайдов для улучшения результата в&nbsp;подарок</p>
                            <p></p>
                        </div> */}
                    {/* </div> */}

                    <div className="jansale_2_start">
                        <SalesWidget hook={widget} id={window.lps_settings.forms.jansale2025_2_id} buttonText="ЗАБРАТЬ ПРОГРАММЫ" />
                    </div>
                    <div className={styles.start_remains}>
                            <div>Осталось мест по спеццене:</div> <span>{window.lps_settings.other.count}</span>
                        <span className={styles.black}></span>
                        </div>
                    {/* <div className={styles.promo_countdown}>
                        <div className={styles.promo_countdown_timer}>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div>
                    </div> */}
                    <div className={styles.pay}>
							<div className={styles.pay_item}>
								<img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/tinkoff.png" alt=""/>
								<p>Рассрочка без процентов от&nbsp;1&nbsp;031&nbsp;₽ в&nbsp;месяц</p>
								<span>Вы&nbsp;сможете оформить заказ в&nbsp;рассрочку: на&nbsp;6&nbsp;месяцев без процентов и&nbsp;предварительного взноса. Только для граждан&nbsp;РФ.</span>
							</div>
							<div className={styles.pay_item}>
								<img src="https://gymteam.kinescopecdn.net/img/l/2024/blackfriday2024/7/doli.png" alt=""/>
								<p>Оплатите Долями, без банка, переплат и&nbsp;рисков</p>
								<span>Не&nbsp;надо ждать до&nbsp;зарплаты&nbsp;&mdash; разделите платёж на&nbsp;4&nbsp;части <b>от&nbsp;1&nbsp;547&nbsp;₽.</b> Всего на&nbsp;6&nbsp;недель. Только для граждан&nbsp;РФ.</span>
							</div>
						</div>
                    {/* <p>Платите с&nbsp;любой карты&nbsp;— выпущенной российским или&nbsp;иностранным банком.</p> */}
                    {/* <div className={styles.start_new}>
                        <div className={styles.start_credit}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/tink_icon.png" alt="" />
                            <div className={styles.start_credit__title}>Рассрочка без&nbsp;процентов<br/> от&nbsp;699&nbsp;<span>₽</span>&nbsp;в&nbsp;месяц</div>
                            <div className={styles.start_credit__text}>
                                <p>Вы можете оформить заказ в&nbsp;рассрочку. На&nbsp;6&nbsp;месяцев без&nbsp;процентов и&nbsp;предварительного взноса (только&nbsp;для&nbsp;граждан&nbsp;РФ).</p>
                            </div>
                        </div>
                        <div className={styles.start_parts}>
                            <div className={styles.start_parts_mark}><img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/mark2.png" alt="" /></div>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/3/start_icon_black.png" alt="" />
                            <div className={styles.start_parts__title}>Оплатите Долями<br/> без&nbsp;банка, переплат и&nbsp;рисков</div>
                            <div className={styles.start_parts__text}>Не надо ждать до&nbsp;зарплаты — разделите платёж <strong>на&nbsp;4&nbsp;части от 1&nbsp;047,5&nbsp;<span>₽</span></strong>. Всего на&nbsp;6&nbsp;недель (только&nbsp;для&nbsp;граждан&nbsp;РФ).</div>
                            <div className={styles.start_parts_block}>
                                <div className={styles.start_parts_item}>
                                    <div className={styles.start_parts_number}><p>1</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>сегодня</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number2].join(' ')}><p>2</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 2&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number3].join(' ')}><p>3</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 4&nbsp;недели</span></p>
                                </div>
                                <div className={styles.start_parts_item}>
                                    <div className={[styles.start_parts_number, styles.start_parts_number4].join(' ')}><p>4</p></div>
                                    <p>от 1&nbsp;047,5&nbsp;<b>₽</b> <span>через 6&nbsp;недель</span></p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={styles.pay}>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Тинькофф-рассрочка</div>
                                <div className={styles.pay_item_text}>Платите потом&nbsp;&mdash; первый платёж через 30&nbsp;дней <b>от&nbsp;748&nbsp;₽</b> в&nbsp;месяц. Без переплат, без процентов, до&nbsp;6&nbsp;месяцев. Только для граждан&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_1.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>По QR-коду</div>
                                <div className={styles.pay_item_text}>Отсканируйте камерой телефона QR-код и&nbsp;перейдите по&nbsp;ссылке в&nbsp;приложение банка, чтобы подтвердить оплату. Удобно со&nbsp;SberPay.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_2.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Долями</div>
                                <div className={styles.pay_item_text}>Разделите платёж на&nbsp;4&nbsp;равные части <b>от&nbsp;1247,5&nbsp;₽</b>. Всего на&nbsp;6&nbsp;недель. Без ожидания одобрения банка, переплат и&nbsp;рисков.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_3.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>PayPal</div>
                                <div className={styles.pay_item_text}>Введите только электронный адрес и&nbsp;пароль&nbsp;&mdash; не&nbsp;нужно вводить номер карты. Безопасный способ платы, доступный клиентам банков не&nbsp;РФ.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_6.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Картой банка&nbsp;РФ<br/> или зарубежного</div>
                                <div className={styles.pay_item_text}>Можете пользоваться своей картой, где&nbsp;бы вы&nbsp;ни&nbsp;находились. Для оплаты картой иностранного банка, выберите оплату через Stripe.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_4.png" alt="" />
                            </div>
                        </div>
                        <div className={styles.pay_item}>
                            <div className={styles.pay_item_left}>
                                <div className={styles.pay_item_title}>Наличными в&nbsp;терминалах</div>
                                <div className={styles.pay_item_text}>Оплатите ваш заказ наличными через ближайший терминал. Быстро и&nbsp;удобно, без использования кредитных карт и&nbsp;необходимости раскрывать личные данные.</div>
                            </div>
                            <div className={styles.pay_item_right}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/3/pay_5.png" alt="" />
                            </div>
                        </div>
                    </div> */}
                </div>

                
            </div>
        </div>
        </>
    )
}

export default Start;