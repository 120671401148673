import Countdown from 'react-countdown';
import styles from './block4.module.css';

const Block4 = ({scroll, renderer, date}) => {
    return (
        <div className={styles.block4}>
            <div className="container">

               <div className={styles.sticky}>
                    <div className={styles.sticky_left}>
                        <div className={styles.sticky_sticky}>
                            <div className={styles.s1}>При покупке в&nbsp;течение 15&nbsp;минут</div>
                            <div className={styles.s2}>дарим 5&nbsp;гайдов для лучших <br/>результатов</div>
                            <div className={styles.s3}>С&nbsp;ними вы&nbsp;сможете тренироваться и&nbsp;не&nbsp;уставать, не&nbsp;тратить много <br/>времени на&nbsp;готовку и&nbsp;наслаждаться вкусными блюдами</div>
                            {/* <div className={styles.promo_countdown}>
                                <p>Успейте забрать любую программу<br/> <b>с&nbsp;выгодой&nbsp;65% и&nbsp;получить ВСЕ бонусы</b></p>
                                <div className={styles.promo_countdown_timer}>
                                    <div>
                                        <Countdown date={Date.now() + date} renderer={renderer} />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className={styles.sticky_right}>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Правила сытного<br/> диетического<br/> ужина</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/gude_1.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как выбрать<br/> подходящее время<br/> для тренировки</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/gude_2.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Сборник </span>
                            <p>Рецепты для<br/> фитнес-<br/> сладкоежек</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/gude_3.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>   
                            <p>Тренажёрный<br/> зал в&nbsp;вашем<br/> кармане</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/gude_4.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция </span>
                            <p>Как готовить,<br/> когда не&nbsp;хватает<br/> времени</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/2/gude_5.png" alt="" />
                        </div>





                        {/* <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как избежать травм:<br/> что нужно знать<br/> о&nbsp;правильной технике</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_1.png" alt="" />
                        </div> */}
                        {/* <div className={styles.sticky_item}>
                            <span>Мини-курс </span>
                            <p>Простые привычки,<br/> которые помогут<br/> поддерживать форму</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_2.png" alt="" />
                        </div> */}
                        {/* <div className={styles.sticky_item}>
                            <span>сборник </span>
                            <p>Книга рецептов<br/> Кати Усмановой</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_3.png" alt="" />
                        </div> */}
                        {/* <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как начать день,<br/> чтобы сжигать больше<br/> калорий</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_4.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Инструкция </span>
                            <p>Как создать тонкую<br/> талию</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_5.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как запустить активное<br/> жиросжигание</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_6.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Путеводитель </span>
                            <p>Прохождение программ<br/> на&nbsp;год</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_7.png" alt="" />
                        </div> */}
                        {/* <div className={styles.sticky_item}>
                            <span>Руководство </span>
                            <p>Всё про планку</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_8.png" alt="" />
                        </div> */}
                        {/* <div className={styles.sticky_item}>
                            <span>Чек-лист </span>
                            <p>Секреты фитнес-режима<br/> для занятых женщин</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_9.png" alt="" />
                        </div>
                        <div className={styles.sticky_item}>
                            <span>Гайд </span>
                            <p>Как восстановиться<br/> после перерыва<br/> в&nbsp;тренировках</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_10.png" alt="" />
                        </div> */}
                        {/* <div className={styles.sticky_item}>
                            <span>Инструкция </span>
                            <p>Источники энергии:<br/> как всегда быть<br/> на&nbsp;пике энергии</p>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/presale_blackfriday/gift_11.png" alt="" />
                        </div> */}
                    </div>
                    <div className={styles.st_mob}>
                        <div className={styles.promo_btn} onClick={scroll}>
                            <p>Забрать подарки</p>
                        </div>
                        <div className={styles.promo_countdown}>
                            <p>Успейте забрать набор<br/> программ по&nbsp;самой выгодной<br/> цене и&nbsp;получить все 5&nbsp;подарков!</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Block4;