import { useEffect, useRef, useState } from "react";
import styles from "./faq.module.css";

const Faq = ({ scroll }) => {
  const faqs = [
    {
      question: "Тренировки проходят в определённое время?",
      answer: (
        <>
          Тренировки открываются по&nbsp;расписанию. Вам нужно только выделить
          20-40 минут в&nbsp;течение дня или вечера и&nbsp;повторять упражнения
          за&nbsp;тренером.
        </>
      ),
    },
    {
      question:
        "Нужно ли покупать абонемент в фитнес-клуб или специальное оборудование?",
      answer: (
        <>
          Программы рассчитаны для тренировок дома. Вы&nbsp;можете обойтись без
          инвентаря и&nbsp;использовать подручные средства, но&nbsp;для лучшего
          результата советуем купить минимальное оборудование: фитнес-резинка
          с&nbsp;сопротивлением medium, наборные или цельные гантели весом
          2-3&nbsp;кг, утяжелители весом 1-2&nbsp;кг.
        </>
      ),
    },
    {
      question:
      "Когда открывается чаты с кураторами?",
      answer: (
        <>
          Чат с&nbsp;кураторами доступен только для тарифа &laquo;Премиум&raquo;. Вы&nbsp;получите доступ к&nbsp;чатам с&nbsp;кураторами марафонов в&nbsp;личном кабинете Gymteam в&nbsp;день старта марафонов, 13&nbsp;января. Также вы&nbsp;получите доступ к&nbsp;общему чату с&nbsp;кураторами в&nbsp;личном кабинете Gymteam в&nbsp;день старта программы тренировок, 20&nbsp;января.
        </>
      ),
    },
    {
      question:
        "Как принять участие в конкурсе?",
      answer: (
        <>
          Подайте заявку для участия в&nbsp;конкурсе в&nbsp;период с&nbsp;20&nbsp;по&nbsp;26&nbsp;января 2025&nbsp;года, заполнив анкету и&nbsp;приложив фотографии &laquo;до&raquo;. Анкету участия в&nbsp;конкурсе нужно заполнить в&nbsp;разделе &laquo;Конкурсный поток&raquo; в&nbsp;личном кабинете Gymteam. Тренируйтесь по&nbsp;программе&nbsp;&mdash; у&nbsp;вас будет 3&nbsp;месяца для получения результата. Заполните анкету и&nbsp;приложите фотографии &laquo;после&raquo; в&nbsp;период с&nbsp;21&nbsp;по&nbsp;27&nbsp;апреля 2025&nbsp;года. Следите за&nbsp;ходом выбора победительниц Катей Усмановой.
        </>
      ),
    },
    {
      question: "Cмогу ли я заниматься, если живу не в России?",
      answer: (
        <>
          Да. Тренировки доступны в любой точке мира. Вам понадобится смартфон,
          планшет или компьютер с доступом в интернет. Вы также можете
          использовать Smart TV.
        </>
      ),
    },
    {
      question:
        "Я оплатила заказ, но мне не пришло письмо с доступом. Что делать?",
      answer: (
        <div>
          <p>
            В день старта 20 января мы отправим письмо со ссылкой на вход в
            личный кабинет. Если вы не нашли письмо даже в папке со спамом,
            проверьте, правильно ли вы указали адрес электронной почты при
            оплате.
          </p>
          <p>
            Обратите внимание, что письмо со ссылкой на личный кабинет приходит
            в течение 24-х часов после оплаты или в день старта.
          </p>
          <p>
            <a href="mailtosupport@gymteam.ru">support@gymteam.ru</a>
          </p>
          <p>
            <a
              href="https://t.me/GymTeamSupportBot"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gymteam.kinescopecdn.net/img/l/common/tg_icon.svg"
                alt=""
              />
            </a>
            &nbsp;&nbsp;
            <a
              href="https://api.whatsapp.com/send?phone=79958871172"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://gymteam.kinescopecdn.net/img/l/common/wa_icon.svg"
                alt=""
              />
            </a>
          </p>
        </div>
      ),
    },
    {
      question: "Я оплатила, как получить доступ к тренировкам?",
      answer: (
        <div>
          <p>
            Вы получите доступ к программам комплекта в личном кабинете GymTeam
            в день старта программы, 20 января. Для доступа к программам
            комплекта вам нужно авторизоваться на сайте или в приложении
            GymTeam.
          </p>
          <p>
            Важно! Авторизация происходит только по номеру телефона, который
            указали при оплате. Обратите внимание, что письмо со ссылкой на
            личный кабинет приходит в течение 24-х часов после оплаты.
          </p>
        </div>
      ),
    },
    {
      question: "Могу я оплатить курс картой иностранного банка?",
      answer: (
        <div>
          <p>
            Да, можете. Всё просто — в блоке с ценой надо нажать «Продолжить» и
            заполнить форму заказа актуальными данными. Потом нажать кнопку
            «Оплатить картой иностранного банка».
          </p>
          <p>
            Откроется платёжная страница, вам надо выбрать удобный способ оплаты
            и нажать кнопку «Оплатить».
          </p>
        </div>
      ),
    },
    // {question: 'Как установить мобильное приложение GymTeam?', answer: <div><p>Прямые ссылки для установки:</p><p><a href="https://apps.apple.com/us/app/id1533404428" target='_blank'>IOS App</a></p><p><a href="https://play.google.com/store/apps/details?id=fit.gymteam.apps.android" target='_blank'>Android App</a></p></div>},
    // {question: 'Как войти в аккаунт GymTeam по номеру телефона через мобильное приложение?', answer: <div><p>ВНИМАНИЕ! Обязательно введите тот номер телефона, который вы указали при покупке.</p><p>Шаг 1 - Введите номер телефона в международном формате, затем нажмите «Подтвердить».</p><p>Шаг 2 - Подтвердите входящий вызов, кликнув на кнопку.</p><p>Шаг 3 - Запомните 4 последние цифры входящего номера телефона. ВНИМАНИЕ! Отвечать на звонок не нужно.</p><p>Шаг 4 - Введите 4 цифры в поле для ввода, далее приложение автоматически произведет вход.</p><p>Шаг 5 - Если вам не поступил входящий вызов, то нажмите на кнопку «Отправить SMS сообщение».</p><p>Шаг 6 - Получите входящее SMS сообщение с кодом для входа, и введите цифры.</p></div>},
  ];

  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      setClicked("0");
    } else {
      setClicked(index);
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  function scrollHandler() {
    const scolling = window.scrollY;
    const block1 = document.querySelector(".new__b1").offsetHeight;
    const block2 = document.querySelector(".new__b2").offsetHeight;
    const block3 = document.querySelector(".new__b3").offsetHeight;
    const block4 = document.querySelector(".new__b4").offsetHeight;
    const block5 = document.querySelector(".new__b5").offsetHeight;
    const block6 = document.querySelector(".new__b6").offsetHeight;
    const block7 = document.querySelector(".new__b7").offsetHeight;
    const fullSize =
      block1 + block2 + block3 + block4 + block5 + block6 + block7;
    const res = scolling - fullSize;
    if (Math.sign(res) == -1) {
      ref.current.style.transform = `translateY(${Math.abs(res) / 6}px)`;
    } else {
      ref.current.style.transform = `translateY(-${Math.abs(res) / 5}px)`;
    }
  }

  return (
    <div className={styles.faq}>
      <div className="container">
        <div className={styles.faq_block}>
          <div className={styles.faq_left}>
            <div className={styles.faq_titles}>
              <div>
                {/* <img src="https://gymteam.kinescopecdn.net/img/l/2023/chp/land/faq.png" alt="" /> */}
              </div>
              <div className={styles.faq_title}>
                Отвечаем
                <br /> на&nbsp;вопросы
              </div>
              <div className={styles.promo_btn} onClick={scroll}>
                <img
                  src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg"
                  alt=""
                />
                <p>
                  Записаться
                  <br /> с&nbsp;выгодой до&nbsp;49%
                </p>
              </div>
            </div>
          </div>
          <div className={styles.faq_right}>
            <div className={styles.accordion}>
              {faqs.map((faq, index) => (
                <div
                  key={faq.answer}
                  className={clicked == index + 1 ? styles.active : ""}
                >
                  <p
                    onClick={() => handleToggle(index + 1)}
                    className={styles.question}
                  >
                    {faq.question}{" "}
                  </p>
                  <p className={styles.answer}>{faq.answer}</p>
                </div>
              ))}
            </div>
            {/* <div className={styles.promo_btn} onClick={scroll}>
                    <p>Забрать комплект программ</p>
                </div> */}
            <div className={styles.oferta}>
              <a href="/l/oferta_metod_c" target="_blank">
                Конкурсная оферта
              </a>
              {/* <a>|</a> */}
              {/* <a href="/l/oferta_metod_p" target="_blank">
                Призовая оферта
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
