import { useEffect, useState } from 'react';
import styles from './platform.module.css';


const Platform = ({isImage, version, isColor, isRadius, image}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
        {isImage
            ?
            <div className="container">
                {windowWidth > 768 ?
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/horizontal.jpg" alt="gymteam" style={{margin: '80px 0'}} />
                :
                <img src="https://gymteam.kinescopecdn.net/img/l/2024/vitrina/vertical.jpg" alt="gymteam" style={{margin: '45px 0'}} />
                }
            </div>
            :
            <div className={`${styles.platformgt} ${version == 2 && styles.platform_black} ${version == 3 && styles.platform_gray}` } style={{backgroundColor: isColor && isColor}}>
            <div className="container platform_container">
                <div className={styles.platformgt_block} style={{borderRadius: isRadius && isRadius}}>
                    <div className={styles.platformgt_logo}><img src="https://gymteam.kinescopecdn.net/img/l/2024/quiz1/logo.svg" alt="" /></div>
                    <div className={styles.platformgt_title}>ТРЕНИРОВКИ ПРОХОДЯТ<br/> НА&nbsp;ПЛАТФОРМЕ GYMTEAM</div>
                    <div className={styles.platformgt_image}>
                        {image ?
                            <>
                                <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/platform.png" alt="" />
                                <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/2024/rasprozharka/platform.png" alt="" />
                            </>
                        :
                            <>
                                <img className={styles.platformgt_image_d} src="https://gymteam.kinescopecdn.net/img/l/common/platform_2.png" alt="" />
                                <img className={styles.platformgt_image_m} src="https://gymteam.kinescopecdn.net/img/l/common/platform_2.png" alt="" />
                            </>
                        }
                        
                    </div>
                    <div className={styles.platformgt_subtitle}>Вы сможете настроить тренировочный процесс под&nbsp;себя и&nbsp;заниматься в&nbsp;удовольствие:</div>
                    <div className={styles.platformgt_info}>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>01</div>
                            <p>Скачивать тренировки на&nbsp;телефон, чтобы&nbsp;<strong>заниматься даже&nbsp;без&nbsp;интернета</strong></p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>02</div>
                            <p>Добавлять тренировки в&nbsp;«Избранное» <strong>и&nbsp;возвращаться к&nbsp;понравившимся упражнениям</strong></p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>03</div>
                            <p><strong>Отмечать</strong> пройденные занятия</p>
                        </div>
                        <div className={styles.platformgt_info__item}>
                            <div className={styles.platformgt_info__item_number}>04</div>
                            <p>Транслировать <strong>видео на&nbsp;TV</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default Platform;