import React, { Fragment, useEffect, useRef } from "react";
import {
  Text,
  Flex,
  Link,
  Heading,
  Box,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Input,
  Image,
} from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";

import { callWebRpc, GLOBAL_CONFIG_KEY } from "@com.marathonium/web2-utils";
import { createInstance, bindFunction } from "@vanekt/rendero-core";
import injectReact from "@vanekt/rendero-react";
import injectScreen from "@vanekt/rendero-screen";

import _set from "lodash/set";
import _get from "lodash/get";
import _has from "lodash/has";
import _forEach from "lodash/forEach";
import _omit from "lodash/omit";
import _startsWith from "lodash/startsWith";
import _trimStart from "lodash/trimStart";
import _includes from "lodash/includes";
import _isFunction from "lodash/isFunction";

import { InputPhone2 } from "./components/InputPhone";
import { InputPin } from "./components/PinInput";
import { AuthWidget } from "./components/AuthWidget";

import { modalSlideFade } from "./transitions";

// FIXME: попробовать обойтись одним лишь isNaN или lodash/isNumber, в зависимости от того, для чего нужна эта функция
function _isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function initRenderoModule(props) {
  const { components = {} } = props || {};

  const jsono = (i, vars, prev) => {
    var c = i[0];
    var p = prev[prev.length - 1];

    if (c == null) {
      return p;
    }

    // print(["c", c]);
    //   // print(["p", p]);
    //     // print(["i", i]);
    //       // print("");
    //

    if (Array.isArray(c)) {
      if (typeof p == "boolean") {
        prev = prev.slice(0, prev.length - 2);
        i = i.slice(1);

        if (p) {
          i = [...c[0], ...i];
        } else {
          i = [...c[1], ...i];
        }

        return jsono(i, vars, prev);
      } else {
        var r = vars[c[0]](p, c[1]);
        prev.push(r);
        return jsono(i.slice(1), vars, prev);
      }
    } else {
      prev.push(vars[c] ?? c);
      return jsono(i.slice(1), vars, prev);
    }
  };

  const proper2 = (typ, props, vars) => {
    if (typ === "link") {
      if (!_get(vars, "auth.isAuthorized") && (_get(props, "href") || "").startsWith("/lessons/")) {
        props["onClick"] = e => {
          e.preventDefault();

          const open = _get(vars, "auth.open");
          _isFunction(open) && open();
        };
      }
    }

    if (typ === "icon2") {
      const cdnIconPath = '//' + _get(window, [GLOBAL_CONFIG_KEY, "cdn_host"], process.env.REACT_APP_CDN_HOST) + "/icons/";
      const imgUrl = "url(" + cdnIconPath + props["name"] + ".svg?ts=" + process.env.REACT_APP_TS + ")";
      props["maskImage"] = imgUrl;
      props["sx.maskSize"] = "contain";
      props["sx.maskRepeat"] = "no-repeat";
      props["bgColor"] = props["color"];

      if (props["multiColor"]) {
        props["bgImage"] = imgUrl;
        props["bgSize"] = "contain";
      }
    }

    if (typ === "static_screen" || typ === "screen") {
      props["vars"] = vars;
    }

    if (typ === "single_child_scroll") {
      if (props["direction"] === "column") {
        props["overflowY"] = "auto";
        props["overflowX"] = "hidden";
      } else if (props["direction"] === "row") {
        props["overflowX"] = "auto";
        props["overflowY"] = "hidden";
      }

      props = _omit(props, ["scrollDirection"]);
    }

    if (_has(props, "backdropFilterBlur")) {
      props["backdropFilter"] = `blur(${props["backdropFilterBlur"]}px)`;
      props = _omit(props, ["backdropFilterBlur"]);
    }

    let x = {};

    _forEach(props, function (v, k) {
      if (_startsWith(k, "_on")) {
        _set(
          x,
          _trimStart(k, "_"),
          bindFunction(
            {
              fn: v,
            },
            vars,
            ["e"]
          )
        );
      } else if (_includes(["true", "false"], v)) {
        _set(x, k, v === "true");
      } else if (typ === "text_node") {
        _set(x, k, v);
      } else {
        if (_isNumeric(v)) {
          if (["zIndex", "opacity", "_hover.opacity", "noOfLines", "flexShrink", "flexGrow"].includes(k)) {
            _set(x, k, parseInt(v));
          } else {
            const scale = vars.width / vars.initWidth || 1;
            _set(x, k, parseFloat(v) * scale + "px");
          }
        } else {
          _set(x, k, v);
        }
      }
    });

    _set(x, "gridGap", _get(x, "spacing"));
    _set(x, "gridColumnGap", _get(x, "spacingY"));
    _set(x, "gridRowGap", _get(x, "spacingX"));

    _set(x, "to", _get(x, "href"));

    if (_has(x, "params")) {
      _set(x, "params", JSON.parse(_get(x, "params")));
    }

    if (_has(x, "maskImage")) {
      _set(x, "sx.maskImage", _get(x, "maskImage"));
    }

    if (_has(x, "fontVariantNumeric")) {
      _set(x, "sx.fontVariantNumeric", _get(x, "fontVariantNumeric"));
    }

    let getAndOmit = n => {
      let v = _get(x, n);
      x = _omit(x, [n]);
      return v;
    };

    let rgpx = getAndOmit("radialGradientPositionX");
    let rgpy = getAndOmit("radialGradientPositionY");
    let rgsx = getAndOmit("radialGradientSizeX");
    let rgsy = getAndOmit("radialGradientSizeY");
    let lga = getAndOmit("linearGradientAngle");

    let gradient;

    if (rgsx) {
      gradient = `radial-gradient(${rgsx} ${rgsy} at ${rgpx} ${rgpy}`;
    }

    if (lga) {
      gradient = `linear-gradient(${lga}`;
    }

    let csi = 1;

    while (true) {
      let csc = getAndOmit(`colorStop${csi}Color`);
      let css = getAndOmit(`colorStop${csi}Stop`);

      if (!csc || !css) {
        break;
      }

      gradient += `,${csc} ${css}`;

      csi++;
    }

    if (gradient) {
      _set(x, "backgroundImage", gradient + ")");
    }

    return _omit(x, ["spacing", "spacingY", "spacingX", "maskImage", "fontVariantNumeric", "withoutShimmer"]);
  };

  return {
    name: "gymteam_ui",
    components: {
      null: () => null,
      state: (props, { render, vars, children }) =>
        render(
          {
            module: "react",
            type: "react_use_state",
            children,
            props,
          },
          vars
        ),
      text_node: (props, { render, vars }) =>
        render({
          module: "react",
          type: "text_node",
          props: proper2("text_node", props, vars),
        }),
      screen: (props, { render, vars }) =>
        render(
          {
            module: "screen",
            type: "screen",
            props: proper2("screen", props, vars),
          },
          vars
        ),
      static_screen: function (props, { render, vars, children }) {
        return render(
          {
            module: "screen",
            type: "static_screen",
            children,
            props: proper2("static_screen", props, vars),
          },
          vars
        );
      },
      icon: ({ key, ...props }, { vars }) => {
        return <Text key={key} as="i" {...proper2("icon", props, vars)} />;
      },
      icon2: ({ key, ...props }, { vars }) => <Box key={key} {...proper2("icon2", props, vars)} />,
      text: ({ key, ...props }, { renderChildren, vars }) => (
        <Text key={key} {...proper2("text", props, vars)}>
          {renderChildren()}
        </Text>
      ),
      flex: ({ key, ...props }, { renderChildren, vars }) => (
        <Flex key={key} {...proper2("flex", props, vars)}>
          {renderChildren()}
        </Flex>
      ),
      box: ({ key, ...props }, { renderChildren, vars }) => (
        <Box key={key} {...proper2("box", props, vars)}>
          {renderChildren()}
        </Box>
      ),
      positioned: ({ key, ...props }, { renderChildren, vars }) => (
        <Box key={key} {...proper2("box", props, vars)}>
          {renderChildren()}
        </Box>
      ),
      single_child_scroll: ({ key, ...props }, { renderChildren, vars }) => (
        <Flex key={key} {...proper2("single_child_scroll", props, vars)}>
          {renderChildren()}
        </Flex>
      ),
      link: ({ key, ...props }, { renderChildren, vars }) => (
        <Link key={key} as={ReactRouterLink} {...proper2("link", props, vars)}>
          {renderChildren()}
        </Link>
      ),
      heading: ({ key, ...props }, { renderChildren, vars }) => <Heading {...proper2("heading", props, vars)}>{renderChildren()}</Heading>,
      simple_grid: ({ key, ...props }, { renderChildren, vars }) => (
        <SimpleGrid key={key} {...proper2("simple_grid", props, vars)}>
          {renderChildren()}
        </SimpleGrid>
      ),
      modal: ({ key, ...props }, { renderChildren, vars }) => (
        <Modal key={key} {...proper2("modal", props, vars)}>
          {renderChildren()}
        </Modal>
      ),
      modal_content: ({ key, ...props }, { renderChildren, vars }) => (
        <ModalContent key={key} motionProps={modalSlideFade} boxShadow="none" {...proper2("modal_content", props, vars)}>
          {renderChildren()}
        </ModalContent>
      ),
      modal_body: ({ key, ...props }, { renderChildren, vars }) => (
        <ModalBody key={key} {...proper2("modal_body", props, vars)}>
          {renderChildren()}
        </ModalBody>
      ),
      modal_close_button: ({ key, ...props }, { vars }) => (
        <ModalCloseButton
          key={key}
          _hover={{
            background: "none",
          }}
          _focusVisible={{
            outline: "none",
          }}
          {...proper2("modal_close_button", props, vars)}
        />
      ),
      modal_overlay: ({ key, ...props }, { renderChildren, vars }) => (
        <ModalOverlay key={key} {...proper2("modal_overlay", props, vars)}>
          {renderChildren()}
        </ModalOverlay>
      ),
      input: ({ key, ...props }, { vars }) => (
        <Input
          key={key}
          border="none"
          _focus={{
            outline: "none",
          }}
          _focusVisible={{
            outline: "none",
          }}
          _invalid={{
            border: "none",
          }}
          {...proper2("input", props, vars)}
        />
      ),
      link_external: ({ key, ...props }, { renderChildren, vars }) => (
        <Link key={key} textDecor="underline" isExternal {...proper2("link_external", props, vars)}>
          {renderChildren()}
        </Link>
      ),
      input_phone: ({ key, ...props }, { vars }) => <InputPhone2 key={key} {...proper2("input_phone", props, vars)} />,
      input_pin: ({ key, ...props }, { vars }) => <InputPin key={key} {...proper2("input_pin", props, vars)} />,
      set_interval: ({ getter, func, delay }, { renderChildren, vars }) => {
        const interval = useRef();

        useEffect(() => {
          interval.current = setInterval(
            bindFunction(
              {
                fn: func,
              },
              {
                ...vars,
                [getter]: interval.current,
              }
            ),
            delay
          );

          return () => {
            clearInterval(interval.current);
          };
        });

        return renderChildren({
          [getter]: interval.current,
        });
      },
      set_timeout: ({ func, delay }, { renderChildren, vars }) => {
        useEffect(() => {
          const t = setTimeout(
            bindFunction(
              {
                fn: func,
              },
              vars
            ),
            delay
          );

          return () => {
            clearTimeout(t);
          };
        });

        return renderChildren();
      },
      fragment: ({ key }, { renderChildren }) => <Fragment key={key}>{renderChildren()}</Fragment>,
      if: ({ condition, key }, { renderChildren }) => {
        return <Fragment key={key}>{condition ? renderChildren() : null}</Fragment>;
      },
      zstack: ({ key, ...props }, { renderChildren, vars }) => (
        <Box key={key} {...proper2("zstack", props, vars)} position="relative">
          {renderChildren()}
        </Box>
      ),
      menu: ({ key, ...props }, { renderChildren, vars }) => (
        <Menu key={key} {...proper2("menu", props, vars)}>
          {renderChildren()}
        </Menu>
      ),
      menu_button: ({ key, ...props }, { renderChildren, vars }) => (
        <MenuButton key={key} {...proper2("menu_button", props, vars)}>
          {renderChildren()}
        </MenuButton>
      ),
      menu_list: ({ key, ...props }, { renderChildren, vars }) => (
        <MenuList key={key} {...proper2("menu_list", props, vars)}>
          {renderChildren()}
        </MenuList>
      ),
      menu_item: ({ key, ...props }, { renderChildren, vars }) => (
        <MenuItem key={key} {...proper2("menu_item", props, vars)}>
          {renderChildren()}
        </MenuItem>
      ),
      menu_divider: ({ key, ...props }, { renderChildren, vars }) => (
        <MenuDivider key={key} {...proper2("menu_divider", props, vars)}>
          {renderChildren()}
        </MenuDivider>
      ),
      eval: ({ code }, { vars }) => <Eval code={code} vars={vars} />,

      auth_widget: ({ key, ...props }, { vars }) => <AuthWidget key={key} {...proper2("auth_widget", props, vars)} />,
      image: ({ key, ...props }, { vars }) => <Image key={key} {...proper2("image", props, vars)} />,
      html: ({ value, key, ...props }, { vars }) => (
        <Box
          key={key}
          dangerouslySetInnerHTML={{ __html: value }}
          sx={{
            a: {
              textDecoration: "underline",
            },
            "a:hover": {
              textDecoration: "none",
            },
          }}
          {...proper2("html", props, vars)}
        />
      ),
      ...components,
    },
  };
}

function Eval({ code, vars }) {
  useEffect(() => {
    if (code) {
      new Function("vars", code)(vars);
    }
  }, []);

  return null;
}

export default createInstance(
  injectReact(),
  injectScreen({
    onLoading: () => null,
    onError: error => {
      console.error(error);
      return null;
    },
    datasources: {
      callWebRpc,
    },
  }),
  initRenderoModule()
);
