import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Author from './components/Author';
import Programs from './components/Programs';
import Students from './components/Students';
import Block4 from './components/Block4';
import Start from './components/Start';
import Faq from './components/Faq';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2025_01_Sale_2 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])
    
    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scroll2 = () => {
      const section = document.querySelector( '.scrollhere2' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    // let nextMidnight = new Date(2024,6,19,0,0,0);
    // let nextMidnight = new Date(Date.UTC(2025,0,26,11,0,0));
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(days>0) {hours += 24*days}
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Январский sale от Кати Усмановой! 8 фитнес-программ с выгодой 81%'/>
        <DocumentDescription value='Январский sale от Кати Усмановой — 8 фитнес-программ с -81% и чат с куратором на 1 месяц' />
        <div className='jansale_2'>
              <Promo scroll={scroll} scroll2={scroll2} date={date} renderer={renderer} />
              <Author />
              <Programs scroll={scroll} date={date} renderer={renderer}/>
              <Students scroll={scroll} />
              <Block4 scroll={scroll} date={date} renderer={renderer}/>
              <Start date={date} renderer={renderer}/>
              <Faq />
              <Footer isColor={'#FDFDFD'} />
        </div>
      </>
    );
};