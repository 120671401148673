import React, { useEffect } from 'react';

import './style.css';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import Preims from './components/Preims';
import Start from './components/Start';
import Faq from './components/Faq';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_12_SpecMu_3 = () => {
    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    // let nextMidnight = new Date();
    // let nextMidnight = new Date(2024,6,19,0,0,0);
    // nextMidnight.setHours(24, 0, 0, 0);
    let nextMidnight = new Date(Date.UTC(2024,11,31,0,0,0));
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();
    const renderer = ({days, hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{days > 0 ? hours+24*days : `${hourzero}${hours}`}<span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
        <DocumentTitle value='Спецпредложение для участниц 1 и 2 потоков Метода Усмановой на 3 поток с выгодой 79%'/>
        <DocumentDescription value='Выгода 79% для участниц 1 и 2 потоков на программу «Метод Усмановой. 3 поток»' />
        <div className='mu3_3'>
              <Promo scroll={scroll} date={date} renderer={renderer} />
              <Preims scroll={scroll} date={date} renderer={renderer}/>
              <Start date={date} renderer={renderer} />
              <Faq scroll={scroll} />
              <Footer isColor={'#DEE5EF'} />
        </div>
      </>
    );
};