import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_block}>
                    <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2024/vitrina/logo_white.svg' alt="gymteam" /></div>
                    <div className={styles.promo_pretitle}><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/flame.svg" alt="" /><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/flame.svg" alt="" /> старт сразу после оплаты</div>
                    <div className={styles.promo_title}><span>ВСЕ В ЗАЛ</span> С&nbsp;КАТЕЙ<br/> УСМАНОВОЙ</div>
                    <div className={styles.promo_subtitle}>Вы еще успеваете <span>подтянуть своё тело</span>.<br/> Занимайтесь вместе с&nbsp;Катей Усмановой на&nbsp;новом курсе,<br/> созданном специально для&nbsp;зала.</div>
                    {/* <div className={styles.promo_icons}>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/icon1.png" alt="" /><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/promo_icon4.png" alt="" /></div>
                            <p>Розыгрыш <b>денежных<br/> призов</b> каждый день</p>
                        </div>
                        <div className={styles.promo_icon}>
                            <div><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka7/icon2.png" alt="" /><img src="https://gymteam.kinescopecdn.net/img/l/2023/rasprozarka2/promo_icon5.png" alt="" /></div>
                            <p>Место в <b>конкурсном<br/> потоке</b> «До/После»</p>
                        </div>
                    </div> */}
                    <div className={styles.promo_bottom}>
                        <div className={styles.promo_btn} onClick={scroll}><p>ПОСМОТРЕТЬ ПРОГРАММУ</p></div>
                        {/* <div className={styles.start_countdown}>
                            <p>До завершения<br/> акции осталось:</p>
                            <div className={styles.start_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo;