import styles from './preims.module.css';
import Countdown from 'react-countdown';

const Preims = ({scroll, date, renderer}) => {
    return (
        <div className={styles.preims}>
                <div className={styles.title}>На&nbsp;программе вас ждёт комплексная <br/><span>система трансформации тела</span></div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_8.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Каждый день новая тренировка с&nbsp;понедельника по&nbsp;пятницу</div>
                        <div className={styles.item_title}>20&nbsp;домашних тренировок в&nbsp;формате &laquo;Повторяй за&nbsp;Катей&raquo;</div>
                        <div className={styles.item_text}>Каждый будний день тебе открывается по&nbsp;1&nbsp;тренировке длительностью 30&ndash;40&nbsp;минут, чтобы проработать мышцы всего тела. Ты&nbsp;сможешь легко влиться в&nbsp;режим и&nbsp;находить время на&nbsp;короткие тренировки каждый день. Тебя ждёт сочетание силовых тренировок с&nbsp;пилатесом, кардио, функциональными 3D-тренировками, укреплением внутренних мышц.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_2.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Каждый день подробное меню и&nbsp;аудиоподкасты от&nbsp;нутрициолога</div>
                        <div className={styles.item_title}>7-дневный FMD-марафон похудения</div>
                        <div className={styles.item_text}>До&nbsp;старта домашних тренировок от&nbsp;Кати мы&nbsp;проведём марафон FMD-похудения в&nbsp;формате живого потока. Марафон поможет вас безопасно сбросить 2-5&nbsp;кг и&nbsp;очистить организм в&nbsp;течение 5&nbsp;дней&nbsp;&mdash; под контролем нашего нутрициолога! После мы&nbsp;поможем за&nbsp;2&nbsp;дня мягко перейти на&nbsp;основное питание, чтобы вы&nbsp;сохранили результат без откатов.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_16.png" alt="" />
                        </div>
                        <div className={styles.info}>Каждый день подробною меню и&nbsp;аудиоподкасты от&nbsp;нутрициолга</div>
                        <div className={styles.item_title}>7-дневный марафон чистого питания</div>
                        <div className={styles.item_text}>До&nbsp;старта мы&nbsp;проведём марафон сбалансированного питания в&nbsp;формате живого потока, который вы&nbsp;можете выбрать как альтернативу FMD-марафону. Вы&nbsp;освоите основы правильного питания и&nbsp;сделаете первые шаги к&nbsp;здоровому образу жизни. Никаких жестких диет и&nbsp;изнуряющих ограничений. Вас ждут: ежедневные практические задания, разбор составления меню и&nbsp;списка продуктов, секреты правильной подготовки и&nbsp;хранения еды и&nbsp;рецепты быстрых и&nbsp;полезных блюд</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_4.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Каждый день марафонов новая тренировка</div>
                        <div className={styles.item_title}>Тренировки на&nbsp;неделю марафонов</div>
                        <div className={styles.item_text}>Каждый день вам открывается 1&nbsp;тренировка длительностью 30-40&nbsp;минут, адаптированная для марафонов FMD и&nbsp;сбалансированного питания. Вас ждут облегчённые тренировки на&nbsp;всё тело. Они позволят плавно втянуться в&nbsp;тренировочный ритм и&nbsp;улучшить ваш результат на&nbsp;выбранном марафоне.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_11.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Все уроки открываются сразу</div>
                        <div className={styles.item_title}>5&nbsp;уроков от&nbsp;нутрициолога</div>
                        <div className={styles.item_text}>Научитесь сами составлять под себя рацион сбалансированного питания, используя удобное правило &laquo;здоровой тарелки&raquo;. Такая система поможет вам избавиться от&nbsp;лишнего веса и&nbsp;поддерживать форму. Вы&nbsp;заметите, как улучшится качество кожи и&nbsp;самочувствия без голодания, строгих диет и&nbsp;подсчёта калорий! Научитесь питаться вкусно, сытно и&nbsp;сбалансированно.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_12.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Выдаётся сразу в&nbsp;начале программы</div>
                        <div className={styles.item_title}>Готовый план питания на&nbsp;2&nbsp;недели и&nbsp;рецепты блюд</div>
                        <div className={styles.item_text}>Вы&nbsp;получите готовый план питания на&nbsp;2&nbsp;недели. От&nbsp;вас требуется только закупить продукты в&nbsp;магазине по&nbsp;нашему списку и&nbsp;готовить по&nbsp;рецептам из&nbsp;него. Также предоставляем 30+&nbsp;рецептов блюд на&nbsp;любой вкус.</div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_13.jpg" alt="" />
                        </div>
                        <div className={styles.info}>1&nbsp;раз в&nbsp;начале программы, дату выберем совместно с&nbsp;участницами</div>
                        <div className={styles.item_title}>Вебинар с&nbsp;ответами на&nbsp;вопросы от&nbsp;нутрициолга</div>
                        <div className={styles.item_text}>В&nbsp;начале программы проведём живой вебинар с&nbsp;нутрициологом. Вы&nbsp;сможете задать свои вопросы и&nbsp;разберётесь во&nbsp;всех нюансах питания.</div>
                    </div>
                    {/* <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_14.jpg" alt="" />
                        </div>
                        <div className={styles.info}>С&nbsp;13&nbsp;января чат FMD-марафона и&nbsp;с&nbsp;20&nbsp;января чат общий чат программы</div>
                        <div className={styles.item_title}>3&nbsp;месяца</div>
                        <div className={styles.item_text}>После окончания месячной программы, у&nbsp;тебя останется доступ к&nbsp;записям уроков, вебинарам и&nbsp;тренировкам ещё на&nbsp;2&nbsp;месяца. Сможешь пройти программу с&nbsp;начала ещё раз и&nbsp;улучшить свой результат.</div>
                    </div> */}
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_15.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Открываются отдельно в&nbsp;начале марафонов и&nbsp;начале домашних тренировок от&nbsp;Кати</div>
                        <div className={styles.item_title}>Чат с&nbsp;кураторами и&nbsp;другими участниками потока</div>
                        <div className={styles.item_text}>Вы&nbsp;попадёте в&nbsp;фитнес-комьюнити, где вас в&nbsp;любую минуту поддержат кураторы и&nbsp;единомышленницы. Сможете задать любой вопрос по&nbsp;тренировкам, питанию и&nbsp;мотивации закрытых чатах марафонов и&nbsp;общем чате учениц, попросить совета или поддержки.</div>
                    </div>
                </div>
                <div className={styles.btn} onClick={scroll}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Принять участие<br/> с&nbsp;выгодой до&nbsp;55%</p>
                </div>
                <div className={styles.promo_countdown}>
                    <p>Предложение<br/> сгорит через:</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div>
            
        </div>
    )
}

export default Preims;