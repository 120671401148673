import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_pretitle}>
                    <div className={styles.promo_left}>Метод <img src='https://gymteam.kinescopecdn.net/img/l/2024/mu3/dt.svg'/> усмановой</div>
                    <div className={styles.promo_right} onClick={scroll}>Записаться на третий поток</div>
                </div>
                <div className={styles.main}>
                    <div className={styles.main_left}>
                        <div className={styles.text}>
                            <div className={styles.pretitle}>Спецпредложение на&nbsp;24&nbsp;часа!</div>
                            <div className={styles.t1}>
                                <p>Запишитесь </p>
                                <p>на&nbsp;Метод Усмановой</p>
                                <p><span>с&nbsp;выгодой до&nbsp;55%</span></p>
                            </div>
                            <div className={styles.st1}>Комплексная программа на&nbsp;всё тело:<br/> фигура, здоровье, питание и&nbsp;мотивация!<br/><b>Создайте стройное и&nbsp;здоровое тело<br/> за&nbsp;28&nbsp;дней!</b></div>
                            {/* <div className={styles.prices}>
                                <div>5&nbsp;990&nbsp;₽</div>
                                <div>3&nbsp;990&nbsp;₽</div>
                            </div> */}
                            <div className={styles.btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Записаться<br/> сейчас</p>
                            </div>
                        </div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo_2.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/promo2_mob.png" alt="" />
                            <div className={styles.prices}>
                                <div>3&nbsp;990&nbsp;₽</div>
                                <div>5&nbsp;990&nbsp;₽</div>
                            </div>
                            <div className={styles.st2}>Наибольшая скидка только сегодня. <b>Такой цены больше не&nbsp;будет!</b></div>
                            <div className={styles.btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Записаться<br/> сейчас</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.main_right}>
                        <div className={styles.promo_countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                         <div className={styles.st2}>Наибольшая скидка только сегодня. <b>Такой цены больше не&nbsp;будет!</b></div>
                    </div>
                </div>

                <div className={styles.dates}>
                    <div className={styles.dates_item}>
                        <div className={styles.dates_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/icon1.png" alt="" />
                        </div>
                        <div className={styles.dates_item_text}>
                            <p>Плановая дата старта:</p>
                            <p>20 января</p>
                        </div>
                    </div>
                    <div className={styles.dates_item}>
                        <div className={styles.dates_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/clock2.png" alt="" />
                        </div>
                        <div className={styles.dates_item_text}>
                            <p>Доступ</p>
                            <p>3 месяца</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo;