import styles from './promo.module.css';
import Countdown from 'react-countdown';

const Promo = ({scroll, scroll2, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.main}>
                    <div className={styles.promo_right}>
                        {/* <div className={styles.promo_pretitle}><img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/snow.svg"/>ПОСЛЕДНИЙ ШАНС КУПИТЬ ДО&nbsp;ПОВЫШЕНИЯ ЦЕН!</div> */}
                        <div className={styles.promo_title}>ЯНВАРСКИЙ<br/> sal<span>e</span><div className={styles.sale}><p>-81%</p></div></div>
                        <div className={styles.promo_subtitle}>Мега-распродажа: набор из&nbsp;5&nbsp;хитов от&nbsp;Кати Усмановой <br/>с&nbsp;доступом на&nbsp;6&nbsp;месяцев. <b>Только до&nbsp;29&nbsp;января</b></div>
                        <div className={styles.bottom_block}>
                            <div className={styles.prize}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/money.png"/>
                                <div className={styles.prize_text}>Самая большая выгода<br/> <span>для первых 100 оплативших</span> </div>
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <div className={styles.btn} onClick={scroll}>ЗАБРАТЬ НАБОР</div>
                            <div className={`${styles.btn} ${styles.btn2}`} onClick={scroll2}>УЗНАТЬ ПОДРОБНЕЕ</div>
                        </div>
                        {/* <div className={styles.countdown_timer}>
                            <p>Закрываем предложение через:</p>
                            <Countdown date={Date.now() + date} renderer={renderer} />
                        </div> */}
                        <div className={styles.promo_discount}>
                            <span className={styles.left}>Осталось мест по&nbsp;спеццене: </span>
                            <span className={styles.count}><span>{window.lps_settings.other.count}</span></span>
                            <div className={styles.left_1}>После чего цена поднимется</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo;