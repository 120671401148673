import { useEffect, useState } from 'react';
import {DocumentTitle, DocumentDescription} from '../../components/DocumentMeta';
import { LoginWidget, AuthWidget, checkUserInGroup, getUserCountByGroup } from "@com.marathonium/web2-ui";
import Footer from '../../components/Footer';
import Promo from './components/Promo';
import { Modal } from './components/Modal';
import { Nomination } from './components/Nomination';
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import styles from './main.module.css';
import './style.css'

const nom1 = [
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/9.jpg',
        name: 'Юлия Д.',
        weight: '67 → 63',
        chest: '92 → 90',
        waist: '76 → 68',
        hips: '104 → 98',
        id: 'e3f5c5c2-e766-44ce-bdee-1a8cd6f66f08',
        place: 1,
    },  
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/1.jpg',
        name: 'Анна М.',
        weight: '72 → 70,2',
        chest: '92 → 91',
        waist: '86 → 79',
        hips: '103 → 101',
        id: 'e055cff0-e63b-4c1e-8e0c-ddb7a6a5c624',
        place: 2,
    },
    // {
    //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/2.jpg',
    //     name: 'Тамара',
    //     weight: '66 → 62,5',
    //     chest: '87 → 89',
    //     waist: '73 → 69',
    //     hips: '103 → 100',
    //     id: 'af17d933-0910-48b6-8fcb-ac9d7d9a24af',
    // },  
    // {
    //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/3.jpg',
    //     name: 'Анна',
    //     weight: '69 → 63,7',
    //     chest: '98 → 94',
    //     waist: '82 → 71',
    //     hips: '102 → 97',
    //     id: '8a84e30d-0390-4fb3-bf32-eb8f8f85349c',
    // },  
    // {
    //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/4.jpg',
    //     name: 'Мария',
    //     weight: '70 → 63',
    //     chest: '95 → 89',
    //     waist: '84 → 73',
    //     hips: '99 → 96',
    //     id: 'd729d22c-ab21-432a-b120-e660487d3151',
    // },  
    // {
    //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/5.jpg',
    //     name: 'Анастасия',
    //     weight: '63,5 → 61',
    //     chest: '91 → 93',
    //     waist: '70 → 64',
    //     hips: '101 → 97',
    //     id: '3b11f2ba-fba6-4cd1-a6a7-7dc5ea18b65f',
    // },  
    // {
    //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/6.jpg',
    //     name: 'Мария',
    //     weight: '59 → 56',
    //     chest: '93 → 89',
    //     waist: '74 → 66',
    //     hips: '95 → 90',
    //     id: '5b8bbcc0-2a8c-4048-a5a4-c04f83732c37',
    // },  
    // {
    //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/7.jpg',
    //     name: 'Ольга',
    //     weight: '64,1 → 56,1',
    //     chest: '96 → 87',
    //     waist: '84 → 73',
    //     hips: '105 → 97',
    //     id: '495fffd9-e394-4649-8ea4-c1630efdd35a',
    // },  
    {
        image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/8.jpg',
        name: 'Валентина Р.',
        weight: '56 → 56',
        chest: '83 → 82',
        waist: '62 → 63',
        hips: '93 → 94',
        id: '718c575a-9966-467a-abd9-f9cd1c842dc0',
        place: 3,
    },  
    
        // {
        //     image: 'https://gymteam.kinescopecdn.net/img/l/2024/missbikini/10.jpg',
        //     name: 'Татьяна',
        //     weight: '62 → 58,2',
        //     chest: '89 → 86',
        //     waist: '71 → 66',
        //     hips: '93 → 92',
        //     id: '752f0d8c-c3cf-4126-84e8-266e94a0ebe7',
        // },    
]
export const L2024_12_Missbikini = () => {
    const [isModal,  setIsModal] = useState(false)
    const [inGroupOne, setInGroupOne] = useState(null)
    const [inGroupTwo, setInGroupTwo] = useState(null)
    const [inGroupThree, setInGroupThree] = useState(null)
    const [inGroupFour, setInGroupFour] = useState(null)
    const [votesOne, setVotesOne] = useState([])
    const [votesTwo, setVotesTwo] = useState([])
    const [votesThree, setVotesThree] = useState([])
    const [votesFour, setVotesFour] = useState([])

    const [alreadyVote1, setAlreadyVote1] = useState(false)
    const [alreadyVote2, setAlreadyVote2] = useState(false)
    const [alreadyVote3, setAlreadyVote3] = useState(false)

    useEffect(() => {
        document.body.id = 'rasprozarka_2305'
        document.body.parentElement.id = 'htmlrasprozarka_2305'

        checkVote1()
    }, [])

    const checkVote1 = () => {
        Promise.all(nom1.map((i) => checkUserInGroup(i.id))).then((result) => {
            result.map((res) => {
                if(res) {
                    setAlreadyVote1(true)
                }
            })
        })
    }
    // const checkVote2 = () => {
    //     Promise.all(nom2.map((i) => checkUserInGroup(i.id))).then((result) => {
    //         result.map((res) => {
    //             if(res) {
    //                 setAlreadyVote2(true)
    //             }
    //         })
    //     })
    // }
    // const checkVote3 = () => {
    //     Promise.all(nom3.map((i) => checkUserInGroup(i.id))).then((result) => {
    //         result.map((res) => {
    //             if(res) {
    //                 setAlreadyVote3(true)
    //             }
    //         })
    //     })
    // }

    // const getVotes = (nomination, number) => {
    //     Promise.all(nomination.map((i) => getUserCountByGroup(i.id))).then((result) => {
    //         number === 1 && setVotesOne(result)
    //         number === 2 && setVotesTwo(result)
    //         number === 3 && setVotesThree(result)
    //         number === 4 && setVotesFour(result)
    //     })
    // }

    function LoginForm() {
        return (
          <>
            <Authorized>
                <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
                {/* <Nomination nomination={nom2} texts={2} setIsModal={setIsModal} alreadyVote={alreadyVote2} checkVote={checkVote2} /> */}
                {/* <Nomination nomination={nom3} texts={3} setIsModal={setIsModal} alreadyVote={alreadyVote3} checkVote={checkVote3} /> */}


                {/* {!inGroupOne && <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesOne} />}
                {!inGroupTwo && <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesTwo} />}
                {!inGroupThree && <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesThree} />}
                {!inGroupFour && <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={true} votes={votesFour} />}

                {inGroupOne && inGroupTwo && inGroupThree && inGroupFour && 
                <>
                <div className={styles.voted}>
                    <div className={styles.voted_title}>ОТКРЫТОЕ<br/> ГОЛОСОВАНИЕ ЗАВЕРШЕНО</div>
                    <div className={styles.voted_subtitle}>Спасибо вам за участие в выборе победительниц!</div>
                    <div className={styles.voted_subtitle}>Мы подведем итоги 4 сентября</div>
                </div>
                <Nomination nomination={nom1} texts={1} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesOne} />
                <Nomination nomination={nom2} texts={2} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesTwo} />
                <Nomination nomination={nom3} texts={3} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesThree} />
                <Nomination nomination={nom4} texts={4} checkVote={checkVote} setIsModal={setIsModal} inGroup={false} votes={votesFour} />
                </>
                } */}
                
                {/* <div className={styles.oferta}>
                    <a href="https://usmanovateam.ru/misshot_competition2023" target='_blank'>КОНКУРСНАЯ ОФЕРТА</a>
                </div> */}
            </Authorized>
            <Anonymous>
                <div className={'scrollhere ' + styles.auth}>
                    <div className={styles.auth_title}>Чтобы участовать в&nbsp;голосовании<br/> нужно авторизоваться</div>
                    <div className={styles.auth_widget + ' auth_safaricontest'}><AuthWidget onSuccess={()=>window.location.reload()} /></div>
                </div>
            </Anonymous>
            {isModal && <Modal />}
          </>
        );
    }

    return (
        <>
        <DocumentTitle value='Мисс Фитнес-бикини 2024 — голосование за лучший результат по Курсу для зала.'/>
        <DocumentDescription value='Мисс Фитнес-бикини 2024 — голосование за финалисток конкурса на лучшие результаты до/после по Курсу для зала Кати Усмановой.' />
        <div className='misshot_2024'>
            <Promo />
            <Nomination nomination={nom1} texts={1} setIsModal={setIsModal} alreadyVote={alreadyVote1} checkVote={checkVote1} />
            {/* <LoginForm /> */}
            {/* <div className={styles.done}>
                <div className={styles.done_block}>
                    <div className={styles.done_img}><img src="https://gymteam.kinescopecdn.net/img/l/2023/velvet/contest/3/done.png" alt="" /></div>
                    <div className={styles.done_title}>ОТКРЫТОЕ ГОЛОСОВАНИЕ<br/> ЗАВЕРШЕНО</div>
                    <div className={styles.done_subtitle}>Мы подведем итоги 17&nbsp;ноября</div>
                    <div className={styles.done_subtitle2}>Спасибо вам за&nbsp;участие в&nbsp;выборе победительниц!</div>
                </div>
            </div> */}
            <Footer isColor={'#E7E6ED'} />
        </div>
        </>
    );
};