import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2024/vitrina/logo_white.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Онлайн-марафон Кати Усмановой</div>
                <div className={styles.promo_title}>Стройность <span>1.0</span></div>
                <div className={styles.promo_subtitle}>Подтянутое и рельефное тело<br/> <strong>за 21 день</strong> домашних тренировок</div>
                <div className={styles.promo_items}>
                    <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/icon_1.png" alt="" /> <p>Для новичков</p></div>
                    <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/icon_2.png" alt="" /> <p>21 тренировка</p></div>
                    <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_str1/icon_3.png" alt="" /> <p>С инвентарём</p></div>
                </div>
                <div className={styles.promo_btns}>
                    <div className='l2211_str1_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_point1}>#быстро<span>похудеть</span></div>
            <div className={styles.promo_point2}>#придать<span>рельеф</span></div>
            <div className={styles.promo_point3}>#подтянуть<span>тело</span></div>
        </div>
    )
}

export default Promo;