import './promo.css';

const Promo = ({scroll}) => {
    return (
        <div className="promo">
          <div className="container">
            <div className='promo_logo'><img src='https://gymteam.kinescopecdn.net/img/l/2024/vitrina/logo.svg' alt="gymteam" /></div>
            <h1>Курс для беременных</h1>
            <p className='promo_subtitle'>С первого триместра до родов</p>
            <p className='promo_info'><strong>Проявите заботу о вашем теле,</strong> психологическом<br /> состоянии и&nbsp;будущем малыше</p>
            <p className='promo_start'>СТАРТ {window.lps_settings.pregnancy.date}</p>
            <button className='main_btn_p' onClick={scroll}>Принять участие</button>
            <div className="promo_points">
              <div className="promo_points__item">
                <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/icon_1.svg' alt="" />
                <p><strong>Тренировки, адаптированные для&nbsp;беременных,</strong> по&nbsp;15-45 минут &mdash; в&nbsp;домашних условиях и&nbsp;удобном режиме</p>
              </div>
              <div className="promo_points__item">
                <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/icon_2.svg' alt="" />
                <p><strong>Рекомендации по&nbsp;питанию для облегчения токсикоза</strong> и&nbsp;других симптомов. Максимум пользы для мамы и&nbsp;малыша</p>
              </div>
              <div className="promo_points__item">
                <img src='https://gymteam.kinescopecdn.net/img/l/pregnancy/icon_3.svg' alt="" />
                <p><strong>Психологическая поддержка</strong> и&nbsp;помощь в&nbsp;работе с&nbsp;негативными эмоциями</p>
              </div>
            </div>
          </div>
        </div>
    );
};

export default Promo;