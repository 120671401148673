import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_info}>
                    <div className={styles.pretitle}>Бесплатная онлайн-тренировка Кати Усмановой</div>
                    <div className={styles.t1}>Как накачать</div>
                    <div className={styles.t2}><span>идеальные ягодицы</span> дома</div>
                    <div className={styles.text}>По&nbsp;чемпионскому методу без бесконечных приседаний, чтобы:</div>
                    <div className={styles.block}>
                        <div className={styles.block_text}>накачать идеальные ягодицы&nbsp;По чемпионскому методу <span>без бесконечных приседаний</span>, чтобы:</div>
                        <div className={styles.item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/promo1.png" alt="" />
                            <p>Создать<br/> <b>фигуру мечты</b></p>
                        </div>
                        <div className={styles.item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/promo2.png" alt="" />
                            <p>Наслаждаться <b>своим<br/> отражением</b></p>
                        </div>
                        <div className={styles.item}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/promo3.png" alt="" />
                            <p>Ловить <b>восхищённые<br/> взгляды</b></p>
                        </div>
                    </div>
                    <div className={styles.btns}>
                        <div className={styles.btn} onClick={scroll}>ЗАПИСАТЬСЯ НА&nbsp;ТРЕНИРОВКУ</div>
                        <div className={styles.btn_text}>
                            <p>Дата: <b>23 декабря</b></p>
                            <p>Время старта: <b>17:00</b></p>
                        </div>
                    </div>
                    <div className={styles.remain}>Осталось мест: <span>{window.lps_settings.other.count}</span>/1000</div>
                </div>
            </div>
        </div>
    )
}

export default Promo;

