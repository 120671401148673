import styles from './author.module.css';

const Author = () => {
    return (
        <div className={styles.author}>
            <div className="container">
                <div className={styles.author_block}>
                    {/* <div className={styles.author_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2023/holidays/author_model.jpg" alt="" />
                    </div> */}
                    <div className={styles.author_info}>
                        <div className={styles.author_title}>Новый год,<br/> новая фигура!</div>
                        <div className={styles.author_subtitle}>С&nbsp;топовыми программами<br/> Кати Усмановой</div>
                        <div className={styles.author_preims}>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>&gt;4&nbsp;000</div>
                                <div className={styles.author_item_text}>учеников со&nbsp;всего мира<br/> тренируются с&nbsp;нами каждый день</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>&gt;300&nbsp;000</div>
                                <div className={styles.author_item_text}>историй успеха, которые начались<br/> с&nbsp;мечты о&nbsp;шикарном теле</div>
                            </div>
                            
                            {/* <div className={styles.author_item}>
                                <div className={styles.author_item_name}>9 из&nbsp;10</div>
                                <div className={styles.author_item_text}>участниц получили желаемый результат</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>&gt;13</div>
                                <div className={styles.author_item_text}>авторских программ для&nbsp;занятий дома и&nbsp;в&nbsp;зале</div>
                            </div>
                            <div className={styles.author_item}>
                                <div className={styles.author_item_name}>&gt;9&nbsp;ЛЕТ</div>
                                <div className={styles.author_item_text}>развиваем индустрию онлайн-фитнеса</div>
                            </div> */}
                        </div>
                        <div className={styles.author_imgmob}><img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/author_model2.png" alt="" /></div>
                        <ul className={styles.author_list}>
                            <li><b>Создатель GymTeam</b>&nbsp;— платформы с&nbsp;безлимитными<br/> тренировками экстра-класса от&nbsp;практикующих экспертов</li>
                            <li><b>Вице-чемпионка мира</b> и&nbsp;чемпионка России<br/> по&nbsp;фитнес-бикини</li>
                            <li><b>Профессиональный фитнес-тренер</b> с&nbsp;опытом более&nbsp;15&nbsp;лет</li>
                            <li>Мама 2-х&nbsp;детей. Всего за&nbsp;100&nbsp;дней после первых родов<br/> <b>похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю форму</b></li>
                            <li><b>Автор первых в&nbsp;России</b> масштабных марафонов стройности</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Author;