import styles from './promo.module.css';

const Promo = ({scroll}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_logo}><img src='https://gymteam.kinescopecdn.net/img/l/2024/vitrina/logo_white.svg' alt="gymteam" /></div>
                <div className={styles.promo_pretitle}>Продвинутая версия легендарного марафона<br/> Екатерины&nbsp;Усмановой</div>
                <div className={styles.promo_title}>МАРАФОН КРАСИВОЙ<br/> И&nbsp;УПРУГОЙ <span>ПОПЫ&nbsp;2.0</span></div>
                <div className={styles.promo_subtitle}><strong>30&nbsp;дней домашних тренировок</strong> на&nbsp;ягодицы<br/> с&nbsp;чемпионкой по&nbsp;фитнес-бикини</div>
                <div className={styles.promo_block_order}>
                    <div className={styles.promo_list}>
                        <ul>
                            <li>Без бесконечных<br/> приседаний</li>
                            <li>Рацион питания<br/> на&nbsp;каждый день</li>
                            <li>Система тренировок<br/> для&nbsp;упругих ягодиц</li>
                        </ul>
                    </div>
                    <div className={styles.promo_items}>
                        <div className={styles.promo_item1}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/icon1_2.png" alt="" /> <p>Для продолжающих</p></div>
                        <div className={styles.promo_item2}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/icon2_2.png" alt="" /> <p>30 тренировок</p></div>
                        <div className={styles.promo_item3}><img src="https://gymteam.kinescopecdn.net/img/l/marafon_popa2/icon3_2.png" alt="" /> <p>С инвентарём</p></div>
                    </div>
                </div>
                <div className={styles.promo_btns}>
                    <div className='l2212_popa2_btn' onClick={scroll}><p>Участвовать</p></div>
                    <div className={styles.promo_date}><p>Старт<br/> {window.lps_settings.dates.mainlands}</p></div>
                </div>
            </div>
            <div className={styles.promo_points_block}>
                <div className={styles.promo_point1}>#придать<span>рельеф</span></div>
                <div className={styles.promo_point2}>#подтянуть<span>тело</span></div>
                <div className={styles.promo_point3}>#быстро<span>похудеть</span></div>
            </div>
        </div>
    )
}

export default Promo;