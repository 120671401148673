import styles from './form.module.css';
import { AuthWidget } from "@com.marathonium/web2-ui";
import { Anonymous, Authorized } from "@com.marathonium/web2-utils";
import { addUserToGroup, checkUserInGroup } from "@com.marathonium/web2-ui";
import { useEffect, useState } from 'react';

// addUserToGroup(groupID);

const Form = () => {
    const [isReg, setIsReg] = useState(false);

    useEffect(()=>{
        checkUserInGroup(window.lps_settings.user_groups.online2024).then(res => {
            if(res !== null) {
                setIsReg(true)
            }
        })
    }, [])

    return (
        <div className={'scrollhere ' + styles.form}>
            <div className="container">
                <div className={styles.block}>
                    <div className={styles.pretitle}>или прийти на&nbsp;онлайн-тренировку и&nbsp;перенять</div>
                    <div className={styles.title}>10&nbsp;лет тренерского<br/> опыта всего за&nbsp;<span>40&nbsp;минут</span><br/> своего времени</div>
                    <Anonymous>
                        <AuthWidget onSuccess={()=>{window.location.reload()}} />
                    </Anonymous>
                    <Authorized>
                        {isReg ? <div className={styles.subtitle} >Вы уже записаны</div> : <div><div className={styles.btn} onClick={()=>{addUserToGroup(window.lps_settings.user_groups.online2024);setIsReg(true)}}>ЗАНЯТЬ МЕСТО</div><div className={styles.remain}>Осталось мест: <span>{window.lps_settings.other.count}</span>/1000</div></div> }
                    </Authorized>
                </div>
            </div>
        </div>
    )
}

export default Form;

