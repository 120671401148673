import styles from './preims.module.css';
import Countdown from 'react-countdown';

const Preims = ({scroll, date, renderer}) => {
    return (
        <div className={styles.preims}>
                <div className={styles.title}>Представьте, что у&nbsp;вас есть персональный тренер. <br/><span>И&nbsp;это&nbsp;&mdash; лично Катя Усманова!</span> <b>В&nbsp;программе:</b></div>
                <div className={styles.block}>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_8.jpg" alt="" />
                            </div>
                            <div className={styles.info}>Каждый день новая тренировка с&nbsp;понедельника по&nbsp;пятницу</div>
                            <div className={styles.item_title}>20&nbsp;домашних тренировок в&nbsp;формате &laquo;Повторяй за&nbsp;Катей&raquo;</div>
                            <div className={styles.item_text}>Каждый будний день вам открывается по&nbsp;1&nbsp;тренировке длительностью 30&ndash;40&nbsp;минут, чтобы проработать мышцы всего тела. Вы&nbsp;сможете легко влиться в&nbsp;режим и&nbsp;находить время на&nbsp;короткие тренировки каждый день. Вас ждёт сочетание силовых тренировок с&nbsp;пилатесом, кардио, функциональными 3D-тренировками, укреплением внутренних мышц.
                            </div>
                        </div>
                        <input type="checkbox" id="pn1" name="pn1" />
                        <label htmlFor="pn1">
                            <p>Результат:</p>
                            <ul>
                                <li>Вы&nbsp;сможете влиться в&nbsp;режим и&nbsp;научишься находить время для тренировок</li>
                                <li>Увидите изменения в&nbsp;форме: рельефные руки, прямая осанка, подтянутая попа и&nbsp;ноги.</li> 
                                <li>Укрепиште мышцы тазового дна, которые жизненно необходимы для женского здоровья.</li>
                            </ul>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_2.jpg" alt="" />
                            </div>
                            <div className={styles.info}>Каждый день подробное меню и&nbsp;аудиоподкасты от&nbsp;нутрициолога</div>
                            <div className={styles.item_title}>7-дневный FMD-марафон похудения</div>
                            <div className={styles.item_text}>До&nbsp;старта домашних тренировок от&nbsp;Кати мы&nbsp;проведем марафон FMD&nbsp;&mdash; похудения. FMD&nbsp;&mdash; диета, имитирующая голодание, но&nbsp;вы&nbsp;все равно остаетесь сытыми. Многие мечтают ее&nbsp;пройти, но&nbsp;это можно делать только под руководством специалиста-профессионала.
                            </div>
                        </div>
                        <input type="checkbox" id="pn2" name="pn2" />
                        <label htmlFor="pn2">
                            <p>Результат:</p>
                            <ul>
                                <li>Вы&nbsp;безопасно сбросите 3-5&nbsp;кг </li>
                                <li>Очистите организм на&nbsp;клеточном уровне. А&nbsp;значит, он&nbsp;будет лучше готов к&nbsp;интенсивному сжиганию лишнего жира и&nbsp;наполнению тела полезными минералами для вашей красоты и&nbsp;здоровья</li>
                                <li>Все&nbsp;&mdash; только под контролем профессионального нутрициолога. Мы&nbsp;&mdash; за&nbsp;вашу безопасность!</li>
                            </ul>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_16.png" alt="" />
                            </div>
                            <div className={styles.info}>Каждый день подробною меню и&nbsp;аудиоподкасты от&nbsp;нутрициолга</div>
                            <div className={styles.item_title}>7-дневный марафон чистого питания</div>
                            <div className={styles.item_text}>Также вы&nbsp;можете выбрать более простой и&nbsp;щадящий вариант&nbsp;&mdash; сбалансированное питание. Все включено: разборы составления меню, рецепты блюд, списки продуктов. От&nbsp;вас&nbsp;&mdash; только берите и&nbsp;делайте!                    
                            </div>
                        </div>
                        <input type="checkbox" id="pn3" name="pn3" />
                        <label htmlFor="pn3">
                            <p>Результат:</p>
                            <ul>
                                <li>Перестанете мучить организм только гречей и&nbsp;куриной грудкой, а&nbsp;потом срываться на&nbsp;булочки</li>
                                <li>Закрепите навык правильных приемов пищи и&nbsp;самостоятельно сможете их&nbsp;применять и&nbsp;после окончания программы</li>
                                <li>Полезная еда станет для вас удовольствием (хотя после куриной грудки, вы, возможно, в&nbsp;это не&nbsp;верите:))</li>
                            </ul>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_4.jpg" alt="" />
                            </div>
                            <div className={styles.info}>Каждый день марафонов новая тренировка</div>
                            <div className={styles.item_title}>Тренировки на&nbsp;неделю марафонов</div>
                            <div className={styles.item_text}>Каждый день во&nbsp;время марафонов по&nbsp;питанию вам открывается 1&nbsp;тренировка длительностью 30-40 минут от&nbsp;Кати и&nbsp;Стаса, профессионального тренера по&nbsp;биомеханике и&nbsp;дыханию. Тренировки направлены на&nbsp;проработку пресса с&nbsp;помощью техники дыхания, ягодиц за&nbsp;счет включения стоп и&nbsp;грудной отдел, а&nbsp;также освоение соматики с&nbsp;дыхательной техникой и&nbsp;медленными движениями.
                            </div>
                        </div>
                        <input type="checkbox" id="pn4" name="pn4" />
                        <label htmlFor="pn4">
                        <p>Результат:</p>
                            <ul>
                                <li>Легкий и&nbsp;плавный вход в&nbsp;интенсивные тренировки, даже если пока вам очень сложно выдерживать физические нагрузки</li>
                                <li>Собственная мотивация (а&nbsp;не&nbsp;когда мотивирует кто-то другой)&nbsp;&mdash; вау, я&nbsp;с&nbsp;этим справилась, я&nbsp;молодец, я&nbsp;хочу дальше!</li>
                                <li>Вы — ракета! Которая вложила много энергии в начале, зато теперь уверенно летит в космос! Осталось только наслаждаться тренировками”</li>
                            </ul>
                        </label>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_11.jpg" alt="" />
                            </div>
                            <div className={styles.info}>Все уроки открываются сразу</div>
                            <div className={styles.item_title}>5&nbsp;уроков от&nbsp;нутрициолога</div>
                            <div className={styles.item_text}>Научитесь сами составлять для себя рацион сбалансированного питания, по методу &laquo;здоровой тарелки&raquo;. Такая система поможет вам избавиться от&nbsp;лишнего веса и&nbsp;поддерживать форму. Вы&nbsp;заметите, как улучшится качество кожи и&nbsp;самочувствия без голодания, строгих диет и&nbsp;подсчёта калорий! Научитесь питаться вкусно, сытно и&nbsp;сбалансированно.</div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_12.jpg" alt="" />
                            </div>
                            <div className={styles.info}>Выдаётся сразу в&nbsp;начале программы</div>
                            <div className={styles.item_title}>Готовый план питания на&nbsp;2&nbsp;недели и&nbsp;рецепты блюд</div>
                            <div className={styles.item_text}>Вы&nbsp;получите готовый план питания на&nbsp;2&nbsp;недели. От&nbsp;вас требуется только закупить продукты в&nbsp;магазине по&nbsp;нашему списку и&nbsp;готовить по&nbsp;рецептам из&nbsp;него. Также предоставляем 30+&nbsp;рецептов блюд на&nbsp;любой вкус.</div>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <div className={styles.item_top}>
                            <div className={styles.item_img}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_13.jpg" alt="" />
                            </div>
                            <div className={styles.info}>1&nbsp;раз в&nbsp;начале программы, дату выберем совместно с&nbsp;участницами</div>
                            <div className={styles.item_title}>Вебинар с&nbsp;ответами на&nbsp;вопросы от&nbsp;нутрициолга</div>
                            <div className={styles.item_text}>В&nbsp;начале программы проведём живой вебинар с&nbsp;нутрициологом. Вы&nbsp;сможете задать свои вопросы и&nbsp;разберётесь во&nbsp;всех нюансах питания.</div>
                        </div>
                    </div>
                    {/* <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_14.jpg" alt="" />
                        </div>
                        <div className={styles.info}>С&nbsp;13&nbsp;января чат FMD-марафона и&nbsp;с&nbsp;20&nbsp;января чат общий чат программы</div>
                        <div className={styles.item_title}>3&nbsp;месяца</div>
                        <div className={styles.item_text}>После окончания месячной программы, у&nbsp;тебя останется доступ к&nbsp;записям уроков, вебинарам и&nbsp;тренировкам ещё на&nbsp;2&nbsp;месяца. Сможешь пройти программу с&nbsp;начала ещё раз и&nbsp;улучшить свой результат.</div>
                    </div> */}
                    <div className={styles.item}>
                        <div className={styles.item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/preims_15.jpg" alt="" />
                        </div>
                        <div className={styles.info}>Открываются отдельно в&nbsp;начале марафонов и&nbsp;начале домашних тренировок от&nbsp;Кати</div>
                        <div className={styles.item_title}>Чат с&nbsp;кураторами и&nbsp;другими участниками потока</div>
                        <div className={styles.item_text}>Вы&nbsp;попадёте в&nbsp;фитнес-сообщество, где вас в&nbsp;любую минуту поддержат кураторы и&nbsp;единомышленницы. Сможете задать любой вопрос по&nbsp;тренировкам, питанию и&nbsp;мотивации закрытых чатах марафонов и&nbsp;общем чате учениц, попросить совета или поддержки.</div>
                    </div>
                </div>
                <div className={styles.btn} onClick={scroll}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                    <p>Принять участие<br/> с&nbsp;выгодой до&nbsp;49%</p>
                </div>
                <div className={styles.promo_countdown}>
                    <p>Предложение<br/> сгорит через:</p>
                    <div className={styles.promo_countdown_timer}>
                        <Countdown date={Date.now() + date} renderer={renderer} />
                    </div>
                </div>
            
        </div>
    )
}

export default Preims;