import Countdown from 'react-countdown';
import styles from './promo.module.css';

const Promo = ({scroll, date, renderer}) => {
    return (
        <div className={styles.promo}>
            <div className="container">
                <div className={styles.promo_pretitle}>
                    <div className={styles.promo_left}>Метод <img src='https://gymteam.kinescopecdn.net/img/l/2024/mu3/dt2.svg'/> усмановой</div>
                    <div className={styles.promo_right} onClick={scroll}>Записаться на третий поток</div>
                </div>
                <div className={styles.main}>
                    <div className={styles.main_left}>
                        <div className={styles.text}>
                            <div className={styles.pretitle}>ПРЕДЛОЖЕНИЕ ТОЛЬКО <br/>УЧАСТНИЦ ОНЛАЙН-ТРЕНИРОВКИ <br/>НА&nbsp;24&nbsp;ЧАСА!</div>
                            <div className={styles.t1}>
                                <p>Запишитесь </p>
                                <p>на&nbsp;Метод Усмановой</p>
                                <p><span>с&nbsp;выгодой до&nbsp;49%</span></p>
                            </div>
                            <div className={styles.st1}><b>Авторская программа Кати на&nbsp;всё тело: создайте стройную и&nbsp;красивую фигуру за&nbsp;28&nbsp;дней!</b><br/>
                            <p>Тренируйтесь дома, питайтесь вкусно и&nbsp;полезно и&nbsp;получайте мотивацию. <span>Впервые в&nbsp;этом потоке! FMD-диета&nbsp;&mdash; от&nbsp;&minus;1,5 до &minus;5&nbsp;кг всего за&nbsp;7&nbsp;дней!</span></p></div>
                            {/* <div className={styles.prices}>
                                <div>5&nbsp;990&nbsp;₽</div>
                                <div>3&nbsp;990&nbsp;₽</div>
                            </div> */}
                            <div className={styles.btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Записаться<br/> сейчас</p>
                            </div>
                        </div>
                        <div className={styles.img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/promo_img.png" alt="" />
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/promo_mob.png" alt="" />
                            {/* <div className={styles.prices}>
                                <div>3&nbsp;990&nbsp;₽</div>
                                <div>5&nbsp;990&nbsp;₽</div>
                            </div> */}
                            <div className={styles.st2}>Самая большая выгода только сегодня. <b>Такой цены больше не&nbsp;будет!</b></div>
                            <div className={styles.btn} onClick={scroll}>
                                <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                                <p>Записаться<br/> сейчас</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.main_right}>
                        <div className={styles.promo_countdown}>
                            <p>Предложение<br/> сгорит через:</p>
                            <div className={styles.promo_countdown_timer}>
                                <Countdown date={Date.now() + date} renderer={renderer} />
                            </div>
                        </div>
                         <div className={styles.st2}>Самая большая выгода только сегодня. <b>Такой цены больше не&nbsp;будет!</b></div>
                         <div className={styles.prize}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/dubai.png" alt=""/>
                            <div className={styles.st2}>Выиграйте поездку в&nbsp;Дубай на&nbsp;тренировку к&nbsp;Кате</div>
                         </div>
                         <div className={styles.btn} onClick={scroll}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/method/arrow_circle_white.svg" alt="" />
                            <p>Записаться<br/> сейчас</p>
                         </div>
                    </div>
                </div>

                <div className={styles.dates}>
                    <div className={styles.dates_item}>
                        <div className={styles.dates_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/icon1.png" alt="" />
                        </div>
                        <div className={styles.dates_item_text}>
                            <p>Плановая дата старта:</p>
                            <p>20 января</p>
                        </div>
                    </div>
                    <div className={styles.dates_item}>
                        <div className={styles.dates_item_img}>
                            <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/clock1.png" alt="" />
                        </div>
                        <div className={styles.dates_item_text}>
                            <p>Доступ</p>
                            <p>3 месяца</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Promo;