import { useEffect } from "react";

export function MyRedirect({url, utm}) {
  
    useEffect(() => {
      // if(!utm) {
        // window.location.replace(url);
      // } else {
        const urlParams = new URLSearchParams(window.location.search);

        const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
        let paramsToAppend = '';

        utmParams.forEach(param => {
          if (urlParams.has(param)) {
            paramsToAppend += `${param}=${urlParams.get(param)}&`;
          }
        });

        paramsToAppend = paramsToAppend.slice(0, -1);

        const targetUrl = url;

        const redirectUrl = paramsToAppend ? `${targetUrl}?${paramsToAppend}` : targetUrl;

        // console.log(redirectUrl)

        window.location.href = redirectUrl;

        // const currentUrl = window.location.href;
        // const urlParams = new URLSearchParams(window.location.search);
        // const paramsToAppend = new URLSearchParams(urlParams).toString();
        // const targetUrl = url;
        // const redirectUrl = paramsToAppend ? `${targetUrl}?${paramsToAppend}` : targetUrl;
        // window.location.href = redirectUrl;
      // }
    }, [url]);
  

    return null;
}