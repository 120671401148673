import React, { useEffect } from "react";
import { URLSearchParamsCatcher } from "@com.marathonium/web2-utils";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { theme } from "./theme";
import "./style.css";

import { L2022_11_Pregnancy } from "./2022-11-pregnancy";
import { L2022_11_Afterpregnancy } from "./2022-11-afterpregnancy";
import { L2022_11_Marafon_str1 } from "./2022-11-marafon-str1";
import { L2022_12_Marafon_abs } from "./2022-11-slimstomach";
import { L2022_12_Marafon_fatburning } from "./2022-12-fatburning";
import { L2022_12_Marafon_popa2 } from "./2022-12-marafonpopa2";
import { L2022_12_Marafon_popa1 } from "./2022-12-marafonpopa1";
import { L2022_12_Marafon_removefat } from "./2022-12-removefat";
import { L2022_12_Marafon_anticellulite } from "./2022-11-anticellulite";
import { L2023_06_Rasprozarka10 } from "./2023/06-rasprozarka10";
import { L2022_12_Coursepop2 } from "./2022-12-coursepop2";
import { L2022_12_Coursepop3 } from "./2022-12-coursepop3";
import { L2022_12_Nutrition } from "./2022-12-nutrition";
import { L2023_01_gtv } from "./2023/gtv";
import { L2023_06_Calculator } from "./2023/06-kbzucalc";
import { TestWidget } from "./testwidget";
import { L2024_07_Thanks } from "./2024/06-order/thanks";
import { L2024_08_Vitrina } from "./2024/08-vitrina";
import { L2024_08_Allprogramms } from "./2024/08-allprogramms";
import { L2024_06_Order } from "./2024/06-order";
import { MyRedirect } from "./2023/gtv/components/redirec";
import { L2023_09_Prolong } from "./2023/08-velvet3/index3";
import { Legal_public } from "./components/oferts/Public";
import { Legal_confidentiality } from "./components/oferts/Confidentiality";
import { Guest } from "./2023/Guest";

import { L2023_03_gtv_price } from "./2023/gtv/price"; // ?
import { L2023_05_Ofertacontest } from "./2023/05-ofertacontest"; // ?
import { L2023_01_Gymteamsale } from "./2023/01-gymteamsale"; // ?
import { L2023_05_Referral } from "./2023/05-referral"; // ?
import { Reftest } from "./2023/reftest"; // ?
import { Refregpage } from "./2023/reftest/refreg"; // ?
import { L2023_06_Ofertacontestgym } from "./2023/06-ofertacontestgym"; // ?
import { TransferPage } from "./2022-12-transfer"; // ?
import { L2023_06_Universalpop } from "./2023/06-universalpop"; // ?
import { L2023_06_Stretchweek } from "./2023/06-stretchweek"; // ?
import { L2023_08_Salemanager } from "./2023/08-velvet3/index2"; // ?
import { L2024_01_Corporate } from "./2024/01-corporate"; // ?
import { L2023_09_Fatlost } from "./2023/09-fatburning"; // ?
import { L2023_09_Fatlost2 } from "./2023/09-fatburning/second"; // ?
import { L2023_09_Fatlost3 } from "./2023/09-fatburning/third"; // ?
import { L2023_09_Fatlost4 } from "./2023/09-fatburning/fourth"; // ?
import { L2023_09_Fatlost5 } from "./2023/09-fatburning/fifth"; // ?
import { L2023_09_Fatlost6 } from "./2023/09-fatburning/six"; // ?
import { L2024_03_8march } from "./2024/03-8march"; // ?
import { L2024_04_Upsale } from "./2024/04-upsale"; // ?
import { L2024_04_Oferta_metod } from "./2023/06-ofertacontestgym/index2"; // ?
import { L2024_08_Oferta_metod_p } from "./2024/08-um2/oferta_p"; // ?
import { L2024_08_Oferta_metod_c } from "./2024/08-um2/oferta_c"; // ?
import { L2024_07_Doit } from "./2024/07-doit";
import { L2024_07_Doit_start_2 } from "./2024/07-doit/index2_2";
import { L2024_08_QuizTest3 } from "./2024/08-quiz-test/index3";
import { L2024_07_Doitreg } from "./2024/07-doitreg";
import { L2024_08_QuizTest2 } from "./2024/08-quiz-test/index2";
import { L2024_07_Um2_base } from "./2024/09-um2_base";
import { L2024_07_Um2_up } from "./2024/08-um2_upsale";
import { L2024_09_Newme } from "./2024/09-newme";
import { L2024_10_Birthday } from "./2024/10-birthday_fitness";
import { L2024_07_Um2 } from "./2024/08-um2";
import { L2024_10_gtv } from "./2024/gtv";
import { L2024_10_Coursegymoctober } from "./2024/10-coursegym_october";
import { L2024_10_SaleRestart } from "./2024/10-sale_restart";
import { L2024_10_prebf } from "./2024/10-prebf";
import { L2024_10_BirthdayUsm } from "./2024/10-birthdayusm";
import { L2024_10_Vitrinathanks } from "./2024/08-vitrina/thanks";
import { L2024_10_prebftest } from "./2024/10-prebf/indextest";
import { L2024_10_specbf } from "./2024/10-specbf";
import { L2024_11_bf2 } from "./2024/11-bf2";
import { L2024_11_bf3 } from "./2024/11-bf3";
import { L2024_11_bf3_update } from "./2024/11-bf3-update";
import { L2024_11_Vpr } from "./2024/11-vpr";
import { L2024_11_bf5 } from "./2024/11-bf5";
import { L2024_11_bfbox } from "./2024/11-bfbox";
import { L2024_11_bf6 } from "./2024/11-bf6-test";
import { L2024_11_bf7 } from "./2024/11-bf7";
import { L2024_12_bf8 } from "./2024/12-bf8";
import { L2024_12_bf9 } from "./2024/12-bf9";
import { L2024_11_bf6_upsale } from "./2024/11-bf6-upsale";
import { L2024_11_bf7_upsale } from "./2024/11-bf7-upsale";
import { L2024_11_bf8_upsale } from "./2024/11-bf7-upsale/index2";
import { L2024_12_checkform } from "./2024/12-checkform";
import { L2024_12_Missbikini } from "./2024/12-missbikini";
import { L2024_07_Mu } from "./2024/07-mu";
import { L2024_12_Mu } from "./2024/12-mu";
import { L2024_12_Mu_Presale_2 } from "./2024/12-mu-presale-2";
import { L2024_12_Mu_3 } from "./2024/12-mu-3";
import { L2024_12_online2024 } from "./2024/12-online2024";
import { L2024_12_SpecMu_3 } from "./2024/12-specmu-3";
import { L2024_12_Mu_3_2 } from "./2024/12-mu-3_2";
import { L2024_12_Mu_3_3 } from "./2024/12-mu-3_3";
import { L2025_01_Newyear2025 } from "./2025/01-newyear2025";
import {L2025_01_Sale} from "./2025/01-jan-sale";
import { L2025_01_Sale_2 } from "./2025/01-jan-sale-2";



function App() {
	useEffect(() => {
		const mode = localStorage.getItem("chakra-ui-color-mode");
		if (mode !== "light") {
			localStorage.setItem("chakra-ui-color-mode", "light");
			window.location.reload(false);
		}
	}, []);

	return (
		<ChakraProvider resetCSS theme={theme}>
			<URLSearchParamsCatcher />
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Routes>
					<Route path="/afterpregnancy" element={<L2022_11_Afterpregnancy />} />
					<Route path="/pregnancy" element={<L2022_11_Pregnancy />} />
					<Route path="/marafonstr1" element={<L2022_11_Marafon_str1 />} />
					<Route path="/marafonabs" element={<L2022_12_Marafon_abs />} />
					<Route path="/fatburning" element={<L2022_12_Marafon_fatburning />} />
					<Route path="/marafonpopa1" element={<L2022_12_Marafon_popa1 />} />
					<Route path="/marafonpopa2" element={<L2022_12_Marafon_popa2 />} />
					<Route path="/removefat" element={<L2022_12_Marafon_removefat />} />
					<Route path="/anticellulite" element={<L2022_12_Marafon_anticellulite />} />
					<Route path="/coursepop2" element={<L2022_12_Coursepop2 />} />
					<Route path="/coursepop3" element={<L2022_12_Coursepop3 />} />
					<Route path="/nutrition" element={<L2022_12_Nutrition />} />
					<Route path="/mainpage" element={<L2024_08_Vitrina />} />
					<Route path="/testwidget" element={<TestWidget />} />
					<Route path="/transfer" element={<TransferPage />} />
					<Route path="/gtv" element={<MyRedirect url={window.lps_settings.regirect + '/start'}/>} />
					<Route path="/gtv2" element={<MyRedirect url={window.lps_settings.regirect + '/start'}/>} />
					<Route path="/price" element={<MyRedirect url={window.lps_settings.regirect + '/price'}/>} />
					<Route path="/gymteamsale" element={<L2023_01_Gymteamsale />} />
					<Route path="/reftest" element={<Reftest />} />
					<Route path="/refregpage" element={<Refregpage />} />
					<Route path="/ofertacontest" element={<L2023_05_Ofertacontest />} />
					<Route path="/ofertacontestgym" element={<L2023_06_Ofertacontestgym />} />
					<Route path="/coursegym" element={<L2023_06_Rasprozarka10 />} />
					<Route path="/referral" element={<L2023_05_Referral />} />
					<Route path="/calculator" element={<L2023_06_Calculator />} />
					<Route path="/universalpop" element={<L2023_06_Universalpop />} />
					<Route path="/stretching" element={<L2023_06_Stretchweek />} />
					<Route path="/legal/public" element={<Legal_public />} />
					<Route path="/legal/confidentiality" element={<Legal_confidentiality />} />
					<Route path="/salemanager" element={<L2023_08_Salemanager />} />
					<Route path="/prolong" element={<L2023_09_Prolong />} />
					<Route path="/fatlost" element={<L2023_09_Fatlost />} />
					<Route path="/fatlost/1year" element={<L2023_09_Fatlost2 />} />
					<Route path="/fatlost/6month" element={<L2023_09_Fatlost3 />} />
					<Route path="/fatlost/3month" element={<L2023_09_Fatlost4 />} />
					<Route path="/fatlost/1month" element={<L2023_09_Fatlost5 />} />
					<Route path="/fatlost/1year15" element={<L2023_09_Fatlost6 />} />
					<Route path="/anketa" element={<Guest />} />
					<Route path="/corporate" element={<L2024_01_Corporate />} />
					<Route path="/8march" element={<L2024_03_8march />} />
					<Route path="/um_special" element={<Navigate to="/mainpage" />} />
					<Route path="/oferta_metod" element={<L2024_04_Oferta_metod />} />
					<Route path="/metodpay" element={<Navigate to="/mainpage" />} />
					<Route path="/metod_start" element={<Navigate to="/mainpage" />} />
					<Route path="/order/:id" element={<L2024_06_Order />} />
					<Route path="/order/:id/:code" element={<L2024_06_Order />} />
					<Route path="/thanks" element={<L2024_07_Thanks />} />

					<Route path="/do-it" element={<MyRedirect url={window.lps_settings.regirect + '/do-it'}/>} />
					<Route path="/do-it-reg" element={<MyRedirect url={window.lps_settings.regirect + '/do-it-reg'}/>} />
					<Route path="/price-new" element={<MyRedirect url={window.lps_settings.regirect + '/price-new'}/>} />

					{/* <Route path="/quiz-test-3" element={<L2024_08_QuizTest2 />} /> */}
					<Route path="/quiz-test" element={<MyRedirect url={window.lps_settings.regirect + '/quiz-test'}/>} />

					<Route path="/prodli_leto24" element={<Navigate to="/metod2" />} />
					<Route path="/prodli_leto2" element={<Navigate to="/metod2" />} />

					<Route path="/allprogramms" element={<L2024_08_Allprogramms />} />
					
					<Route path="/specoffer2_metod2" element={<Navigate to="/birthday_fitness" />} />
					
					<Route path="/oferta_metod_c" element={<L2024_08_Oferta_metod_c />} />
					<Route path="/oferta_metod_p" element={<L2024_08_Oferta_metod_p />} />
					<Route path="/metod2" element={<Navigate to="/birthday_fitness" />} />
					<Route path="/presale2_metod2" element={<Navigate to="/metod2" />} />

					<Route
						path="/open_training"
						element={<MyRedirect url="https://usmanovateam.ru/open_training" />}
					/>
					<Route
						path="/webinar_show"
						element={
							<MyRedirect url="https://usmanovateam.ru/open_training/web" />
						}
					/>
					<Route
						path="/open_train_dec"
						element={
							<MyRedirect url="https://usmanovateam.ru/open_train_dec" />
						}
					/>

					<Route path="/metod_base" element={<Navigate to="/mainpage" />} />
					<Route path="/lks_december" element={<Navigate to="/mainpage" />} />
					<Route path="/reg_metod2" element={<L2024_07_Mu />} />
					<Route path="/new_me" element={<Navigate to="/birthday_fitness" />} />
					<Route path="/birthday_fitness" element={<Navigate to="/mainpage" />} />

					<Route path="/coursegym_october" element={<Navigate to="/mainpage" />} />
					<Route path="/sale_restart" element={<Navigate to="/mainpage" />} />
					
					<Route path="/blackfriday2024" element={<Navigate to="/mainpage" />} />
					<Route path="/specoffer2_blackfriday2024" element={<Navigate to="/mainpage" />} />
					
					<Route path="/bf_boxes" element={<L2024_11_bfbox />} />
					<Route path="/missbikini" element={<L2024_12_Missbikini />} />
					
					<Route path="/blackfriday2024_2" element={<L2024_11_bf3 />} />
					<Route path="/specoffer_blackfriday2024" element={<Navigate to="/mainpage" />} />
					<Route path="/checkform" element={<L2024_12_checkform />} />
					<Route path="/test" element={<L2024_07_Um2 />} />
					
					<Route path="/presale_method3" element={<L2024_12_Mu />} />
					<Route path="/presale2_method3" element={<L2024_12_Mu_Presale_2 />} />
					<Route path="/online2024" element={<L2024_12_online2024 />} />
					<Route path="/method3" element={<Navigate to="/mainpage" />} />
					
					<Route path="/newyear2025" element={<Navigate to="/method3" />} />
					<Route path="/january_sale" element={window.lps_settings.other.active_land == 1 ? <L2025_01_Sale /> 
														: window.lps_settings.other.active_land == 2 ? <L2025_01_Sale_2 /> 
														: <L2025_01_Sale_2 />}
														/>

					<Route path="/january_sale_2_test" element={<L2025_01_Sale_2 />} />

					<Route path="/specoffer_method3" element={<L2024_12_SpecMu_3 />} />
					
					<Route path="/testbutton" element={<L2024_10_prebftest />} />
					<Route path="/test_forma" element={<L2024_10_BirthdayUsm version={1} />} />
					<Route path="/test_auth" element={<L2024_10_BirthdayUsm version={2} />} />
					
					<Route path="/mainpage/thx_mail" element={<L2024_10_Vitrinathanks />} />
				</Routes>
			</BrowserRouter>
		</ChakraProvider>
	);
}

export default App;
