import styles from './second.module.css';

const Second = ({scroll}) => {
    return (
        <div className={styles.second}>
                <div className={styles.block}>
                    <div className={styles.img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2025/jansale/second3.jpg" alt="" />
                    </div>
                    <div className={styles.info}>
                        <div className={styles.info_title}>И&nbsp;успевайте забрать <span>самую большую выгоду!</span></div>
                        <div className={styles.info_text}>+ ПОДАРОК для тех, кто действует быстро:<br/> <span>доступ к&nbsp;чату с&nbsp;куратором на&nbsp;1&nbsp;месяц!</span></div>
                        <div className={styles.btn} onClick={scroll}>Забрать лучшее предложение</div>
                        {/* <div className={styles.promo_discount}>
                            <div>
                                <span className={styles.left}>Осталось комплектов: </span>
                                <span className={styles.count}>
                                <span>{window.lps_settings.other.count}/100</span></span>
                            </div>
                            <div className={styles.left_1}>После чего цена повысится</div>
                        </div> */}
                    </div>
                </div>
        </div>
        
    )
}

export default Second;