import { addUserToGroup } from "@com.marathonium/web2-ui";
import styles from './nomination.module.css'
import { useState } from "react";

export const Nomination = ({nomination, texts, checkVote, setIsModal, inGroup, votes, alreadyVote}) => {

    const [isModal2, setIsModal2] = useState(false)
    const [activeSlide, setActiveSlide] = useState(null)

    function getVote(id) {
        if(!alreadyVote) {
            setIsModal(true)
            addUserToGroup(id).then((res) => {
                checkVote()
                setTimeout(()=>{
                    setIsModal(false)
                }, 1000)
            })
        }
    }

    return (
        <div className={'scrollhere ' + styles.nomination}>
            <div className="container">

                {/* <div className={styles.t1}>Выбери лучший результат<br/> работы над телом</div> */}
                <div className={styles.t1}>ПОБЕДИТЕЛЬНИЦЫ КОНКУРСА</div>
                {/* <div className={styles.t2}>
                    <p>Оценивай общее изменение качества тела и&nbsp;отдай свой голос той,<br/> которая показала наилучший результат</p>
                </div> */}
            
            {/* {texts === 1 ? <div className={styles.nomination_title}>Наша победительница<br/> на&nbsp;Мисс завидный пресс</div> : ''}
            {texts === 2 ? <div className={styles.nomination_title}>Наши победительница<br/> на&nbsp;безупречные ягодицы</div> : ''}
            {texts === 3 ? <div className={styles.nomination_title}>Наши победительница<br/> на&nbsp;мисс королева<br/> стройности</div> : ''} */}
            
            {/* {texts === 1 ? <div className={styles.nomination_subtitle}>Оценивайте результат работы над прессом</div> : ''} */}
            {/* {texts === 2 ? <div className={styles.nomination_subtitle}>Оценивайте результат работы над качеством и&nbsp;формой ягодиц</div> : ''} */}
            {/* {texts === 3 ? <div className={styles.nomination_subtitle}>Оценивайте результат работы над изменением объемов и&nbsp;массы тела</div> : ''} */}

            {/* {texts === 1 ? <div className={styles.nomination_subtitle2}>Чьи кубики достойны вашего голоса?</div> : ''} */}
            {/* {texts === 2 ? <div className={styles.nomination_subtitle2}>Чья попа достойна вашего голоса?</div> : ''} */}
            {/* {texts === 3 ? <div className={styles.nomination_subtitle2}>Чьё похудение достойно вашего голоса?</div> : ''} */}
            
            <div className={styles.nomination_block}>
                {nomination.map((i, idx) => {
                    return(
                    <div className={styles.nomination_item} key={i.name}>
                        <div className={styles.nomination_item_info}>
                          
                        {/* onClick={() => (setActiveSlide(i[idx].image), setIsModal(true))} */}
                            <div className={styles.nom_img} onClick={() => (setActiveSlide(i.image), setIsModal2(true))}><img src={i.image} alt="" /></div>
                            <div className={styles.nom_info}>
                            
                                <div className={styles.nom_top}>
                                    <div className={styles.nom_name}>
                                        <div>{i.name}</div> 
                                    {/* <div>Баллов: {i.point}</div> */}
                                    </div>
                                    {i.place==1 && <div className={styles.nom_place}>1 место</div>}
                                    {i.place==2 && <div className={styles.nom_place}>2 место</div>}
                                    {i.place==3 && <div className={styles.nom_place}>3 место</div>}
                                    {/* <div className={styles.nom_name}>Баллов: <span>{i.points}</span></div> */}
                                    {/* <div className={`${alreadyVote ? styles.nom_btn_closed : styles.nom_btn}`} onClick={()=>getVote(i.id)}>Проголосовать</div> */}
                                </div>
                                <div className={styles.nom_sizes}>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/nom1.png" alt="" /> <b>Вес:</b>&nbsp;{i.weight}</div>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/nom2.png" alt="" /> <b>Талия:</b>&nbsp;{i.waist}</div>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/nom3.png" alt="" /> <b>Грудь:</b>&nbsp;{i.chest}</div>
                                    <div><img src="https://gymteam.kinescopecdn.net/img/l/2024/missstroinost24/nom4.png" alt="" /> <b>Бедра:</b>&nbsp;{i.hips}</div>
                                </div>
                            </div>
                        </div>
                        {/* <h2>НАБРАНО ГОЛОСОВ: {votes ? votes[idx] : 0}</h2> */}
                    </div>
                    )
                })}
            </div>

            </div>

            <div className={`${styles.modal} ${isModal2 && styles.modal_active}`}>
                <div className={styles.close_btn} onClick={()=>setIsModal2(false)}>
                    <svg viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.1949 1.48389L1.33203 15.3468M1.33203 1.48389L15.1949 15.3468" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className={styles.modal_img}>
                    <img src={activeSlide} alt="" />
                </div>
            </div>
        </div>
    );
};