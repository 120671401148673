import React, { useEffect } from 'react';
import { DocumentDescription, DocumentTitle } from '../../components/DocumentMeta';
import Promo from './components/Promo';
import styles from './style.module.css';
import Author from './components/Author';
import Form from './components/Form';
import Students from './components/Students';
const Footer = React.lazy(() => import('../../components/Footer'));

export const L2024_12_online2024 = () => {

    useEffect(() => {
      document.body.id = 'reset'
      document.body.parentElement.id = 'html_reset'
    }, [])

    const scroll = () => {
      const section = document.querySelector( '.scrollhere' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    
    return (
      <>
        <DocumentTitle value='Повтор вебинара Кати Усмановой'/>
        <DocumentDescription value='Повтор вебинара Кати Усмановой' />
        <div className={styles.main}>
            <Promo scroll={scroll} />
            <Author scroll={scroll} />
            <Form />
            <Students scroll={scroll}/>
            <Footer isColor={'#FDFDFD'} />
        </div>
      </>
    );
};