import styles from './gift.module.css';

const Gift = () => {
    return (
        <div className={styles.gift}>
            <div className="container">
                <div className={styles.titles}>
                    <div className={styles.title}>
                        <p>Оплатив сейчас,</p>
                        <p><span>вы&nbsp;получаете</span></p>
                        <p>подарок</p>
                        <div>Вебинар &laquo;Психосоматика лишнего<br/> веса&raquo; от&nbsp;психолога, который работал<br/> с&nbsp;участницами предыдущих потоков<br/> Метода Усмановой</div>
                    </div>
                    <div className={styles.titles_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu/gift.jpg" alt="" />
                    </div>
                </div>

                <div className={styles.tags}>
                    <div className={styles.tags_title}>Из&nbsp;вебинара вы&nbsp;узнаете:</div>
                    <div className={styles.tags_items}>
                        <div>Что такое лишний вес и&nbsp;зачем он&nbsp;нам нужен, <b>хотя мы&nbsp;даже об&nbsp;этом не&nbsp;подозреваем</b></div>
                        <div>Что такое пищевое поведение <b>и&nbsp;его расстройства</b></div>
                        <div>Как отличить психологический голод от&nbsp;физического <b>и&nbsp;наконец-то перестать переедать</b></div>
                        <div>Алгоритм осознанного питания, <b>чтобы забыть о&nbsp;срывах</b></div>
                        <div>Что делать при психологическом голоде</div>
                        <div>Что делать при срывах</div>
                        <div><b>Практика по&nbsp;работе с&nbsp;психосоматикой лишнего веса</b></div>
                    </div>
                </div>

                <div className={styles.last}>
                    <div className={styles.last_pretitle}>Но&nbsp;и&nbsp;это ещё не&nbsp;всё!</div>
                    <div className={styles.last_title}>Дарим <span>10&nbsp;полезных гайдов</span> при покупке</div>
                    <div className={styles.last_text}>С&nbsp;ними вы&nbsp;научитесь тратить меньше времени на&nbsp;готовку, снизите<br/> потребление сахара и&nbsp;сделаете тренировки эффективнее</div>
                    
                </div>
                <div className={styles.guides}>
                    <div className={styles.guide_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_5.png" alt=''/>
                        <div className={styles.guide_title}>Гайд</div>
                        <div className={styles.guide_subtitle}>Всё о&nbsp;целлюлите: что это, почему возникает и&nbsp;как от&nbsp;него избавиться</div>
                        <div className={styles.guide_text}>Узнаете о&nbsp;преимуществах и&nbsp;недостатках разных методов по&nbsp;борьбе с&nbsp;целлюлитом и&nbsp;сможете выбрать тот, что подходит именно вам. Также получите универсальный чек-лист по&nbsp;избавлению от&nbsp;&laquo;апельсиновой корки&raquo;.</div>
                    </div>
                    <div className={styles.guide_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_2.png" alt=''/>
                        <div className={styles.guide_title}>Инструкция</div>
                        <div className={styles.guide_subtitle}>&laquo;Как избавиться от&nbsp;тяги к&nbsp;сладкому и&nbsp;перестать быть сладкоежкой&raquo;</div>
                        <div className={styles.guide_text}>Не&nbsp;можете пройти мимо конфет и&nbsp;тортиков? Разберётесь, что стоит за&nbsp;тягой к&nbsp;быстрым углеводам&nbsp;&mdash; а&nbsp;это целых 6&nbsp;причин! Получите целый комплекс действий, как снизить тягу к&nbsp;сладкому.</div>
                    </div>
                    <div className={styles.guide_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_4.png" alt=''/>
                        <div className={styles.guide_title}>Инструкция по&nbsp;питанию:</div>
                        <div className={styles.guide_subtitle}>Как составить сбалансированный рацион</div>
                        <div className={styles.guide_text}>Узнаете главные принципы сбалансированного питания и&nbsp;научитесь строить на&nbsp;них свой рацион: питаться сытно, использовать &#8470;правило здоровой тарелки&quot; на&nbsp;практике и&nbsp;выбирать продукты по&nbsp;упаковке.</div>
                    </div>
                    <div className={styles.guide_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_1.png" alt=''/>
                        <div className={styles.guide_title}>Гайд</div>
                        <div className={styles.guide_subtitle}>&laquo;Альтернатива: чем заменить упражнения, которые вызывают дискомфорт&raquo;</div>
                        <div className={styles.guide_text}>Вы&nbsp;научитесь подбирать программу тренировок под себя! Тяжело делать выпады? Не&nbsp;можете стоять в&nbsp;планке? Используйте любое из&nbsp;альтернативных упражнений, которое задействует ту&nbsp;же группу мышц и&nbsp;занимайтесь в&nbsp;удовольствие.</div>
                    </div>
                    
                    <div className={styles.guide_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_3.png" alt=''/>
                        <div className={styles.guide_title}>Инструкция</div>
                        <div className={styles.guide_subtitle}>Как снять боль в&nbsp;мышцах после тренировки</div>
                        <div className={styles.guide_text}>Боитесь даже начать тренироваться, потому что на&nbsp;следующее утро не&nbsp;сможете встать с&nbsp;постели? Мы&nbsp;предлагаем комплекс из&nbsp;5&nbsp;шагов, как предотвратить боль в&nbsp;мышцах и&nbsp;рекомендации, что делать, если ваще тело болит. Это повысит эффективность вашей программы и&nbsp;даст вам результат быстрее.</div>
                    </div>

                    <div className={styles.guide_item}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_6.png" style={{marginBottom: 15}} alt=''/>
                        <div className={styles.guide_title}>Гайд</div>
                        <div className={styles.guide_subtitle}>Как избежать травм: что нужно знать о&nbsp;правильной технике</div>
                        {/* <div className={styles.guide_text}></div> */}
                    </div>
                    <div className={styles.guide_item} style={{minHeight: 'auto'}}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_7.png" style={{marginBottom: 15}} alt=''/>
                        <div className={styles.guide_title}>Мини-курс</div>
                        <div className={styles.guide_subtitle}>Простые привычки, которые помогут поддерживать форму</div>
                        {/* <div className={styles.guide_text}></div> */}
                    </div>
                    <div className={styles.guide_item} style={{minHeight: 'auto'}}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_8.png" style={{marginBottom: 15}} alt=''/>
                        <div className={styles.guide_title}>Гайд</div>
                        <div className={styles.guide_subtitle}>Как начать день, чтобы сжигать больше калорий</div>
                        {/* <div className={styles.guide_text}></div> */}
                    </div>
                    <div className={styles.guide_item} style={{minHeight: 'auto'}}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_9.png" style={{marginBottom: 15}} alt=''/>
                        <div className={styles.guide_title}>Чек-лист</div>
                        <div className={styles.guide_subtitle}>Секреты фитнес-режима для занятых женщин</div>
                        {/* <div className={styles.guide_text}></div> */}
                    </div>
                    <div className={styles.guide_item} style={{minHeight: 'auto'}}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/mu3/guide_10.png" style={{marginBottom: 15}} alt=''/>
                        <div className={styles.guide_title}>Гайд</div>
                        <div className={styles.guide_subtitle}>Как восстановиться после перерыва в&nbsp;тренировках</div>
                        {/* <div className={styles.guide_text}></div> */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Gift;