import styles from './author.module.css';

const Author = ({scroll}) => {
    return (
        <>
        <div className={styles.top}>
            <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/line_x2.jpg" alt="" />
        </div>
        
        <div className={styles.author}>
            <div className="container">
                <div className={styles.b1}>
                    <div className={styles.b1_img}>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/author.png" alt="" />
                    </div>
                    <div className={styles.b1_info}>
                        <div className={styles.b1_title}>Катя Усманова</div>
                        <div className={styles.b1_subtitle}>знает секреты создания<br/> красивой попы</div>
                        <div className={styles.b1_text}>Более 9&nbsp;лет она создаёт топовые программы для идеальных<br /> ягодиц, плоского живота и&nbsp;стройности без жёстких диет.<br/> За&nbsp;это время <b>более 300 тысяч девушек изменили своё тело<br/> и&nbsp;жизнь.</b></div>
                        <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/author.png" alt="" />
                        <ul>
                            <li><b>Вице-чемпионка мира</b> и&nbsp;чемпионка России по&nbsp;фитнес-бикини</li>
                            <li><b>Автор первых в&nbsp;России</b> масштабных марафонов стройности</li>
                            <li>Профессиональный фитнес-тренер <b>с&nbsp;опытом более 15&nbsp;лет</b></li>
                            <li><b>Мама 2-х детей.</b> Всего за&nbsp;100 дней после первых родов похудела на&nbsp;20&nbsp;кг и&nbsp;вернулась в&nbsp;прежнюю форму</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.bottom_img}>
                    <img src="https://gymteam.kinescopecdn.net/img/l/2024/online2024/line_mob.jpg" alt="" />
                </div>
                <div className={styles.b2}>
                    <div className={styles.b2_title}>ВЫ&nbsp;МОЖЕТЕ СДЕЛАТЬ ПЕРВЫЙ ШАГ<br/> ПРЯМО СЕЙЧАС</div>
                    <div className={styles.b2_btn} onClick={scroll}>ЗАПИСАТЬСЯ СЕЙЧАС</div>
                    <div className={styles.remain}>Осталось мест: <span>{window.lps_settings.other.count}</span>/1000</div>
                    <div className={styles.b2_subtitle}>🤔 Или подождать ещё...</div>
                    <span>или подождать ещё и&nbsp;придумывать новые отговорки, почему не&nbsp;стоит даже начинать.</span>
                </div>
            </div>
        </div>

        <div className={styles.bottom}>
            <div className="container">
                <div className={styles.bottom_title}>И&nbsp;находить <span>новые<br/> отговорки,</span></div>
                <div className={styles.bottom_subtitle}>почему не&nbsp;стоит даже начинать</div>
            </div>
        </div>
        </>
    )
}

export default Author;

