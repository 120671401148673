import { getCookie, setCookie } from "../helpers/cookies";

const KEYS = ["utm_source", "utm_medium", "utm_content", "referral"];
const COOKIE_MAX_AGE = 30 * 24 * 60 * 60;

export class URLSearchParamsService {
  static get params() {
    return new URLSearchParams(window.location.search);
  }

  static get(keys = KEYS) {
    const result = {};

    keys.forEach(key => {
      result[key] = URLSearchParamsService.params.get(key);
    });

    return result;
  }

  static save(keys = KEYS) {
    const entries = Object.entries(URLSearchParamsService.get(keys));

    if (entries.every(([, value]) => value === null)) {
      return;
    }

    entries.forEach(([key, value]) => {
      if (value) {
        setCookie(key, value, { "max-age": COOKIE_MAX_AGE });
      } else {
        setCookie(key, "", { "max-age": 0 });
      }
    });
  }

  static load(keys = KEYS) {
    const result = {};

    keys.forEach(key => {
      const value = getCookie(key);
      if (!value) {
        return;
      }

      result[key] = value;
    });

    return result;
  }

  static clear(keys = KEYS) {
    keys.forEach(key => {
      setCookie(key, "", { "max-age": 0 });
    });
  }
}
