import { DocumentDescription, DocumentTitle } from '../components/DocumentMeta';
import { RedirectPrograms } from '../components/redirect/RedirectProgram';
import Author from './components/Author';
import Faq from './components/Faq';
import Footer from '../components/Footer';
import Inventory from './components/Inventory';
import Marforme from './components/Marforme';
import Onmarafon from './components/Onmarafon';
import Promo from './components/Promo';
import Start from './components/Start';
import Studentres from './components/Studentres';
import Whaityou from './components/Whaityou';
import Createbody from './components/Createbody';
import Platform from '../components/platform/Platform';
import Noinventory from './components/Noinventory';
import './style.css';

export const L2022_12_Marafon_popa1 = () => {
    const scroll = () => {
        const section = document.querySelector( '.scrollhere' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    const scrollStart = () => {
      const section = document.querySelector( '.scrollstart' );
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    let nextMidnight = new Date();
    nextMidnight.setHours(24, 0, 0, 0);
    const now = new Date();
    const date = nextMidnight.getTime() - now.getTime();

    const renderer = ({hours, minutes, seconds, completed}) => {
        let hourzero = '';
        let minuteszero = '';
        let secondszero = '';
        if(hours<10) {hourzero = '0'}
        if(minutes<10) {minuteszero = '0'}
        if(seconds<10) {secondszero = '0'}
        return (
            <div>
                <div>{hourzero}{hours} <span>:</span></div>
                <div>{minuteszero}{minutes} <span>:</span></div>
                <div>{secondszero}{seconds}</div>
            </div>
        );
    };
    
    return (
      <>
      <RedirectPrograms />
      <DocumentTitle value='Упругая попа 1.0 — марафон Екатерины Усмановой в GymTeam'/>
      <DocumentDescription value='«Марафон красивой и упругой попы 1.0» в GymTeam — программа Екатерины Усмановой на 21 день тренировок. Цель: накачать ягодицы и подтянуть ноги, сделать попу идеальной.' />
      
        <div className='marafonpopa1'>
            <Promo scroll={scroll} />
            <Createbody />
            <Onmarafon />
            <Whaityou />
            <Studentres scrollStart={scrollStart} />
            <Platform isImage={true} />
            <Marforme />
            <Inventory />
            <Noinventory />
            <Start date={date} renderer={renderer} />
            <Author />
            <Faq scroll={scroll} />
            <Footer />
        </div>
      </>
    );
};